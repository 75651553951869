import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

function EntertainmentFeatureForm({
  type,
  setStep,
  setFormFilledData,
}) {

  const features = useSelector(state => state.multiStepForm.entertainment.features);
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: features
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "features", // unique name for your Field Array
  });


  const backStep = () => {
    setStep(2);
  }
  const onSubmit = (data) => {
    // console.log("Submitted data ===", data)
    setFormFilledData(data, type);
    setStep(4);
  };
  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>

              {fields.map((field, index) => (
                <div className="col-lg-12 col-sm-12">
                  <label className="form-label-dk">Feature {index + 1}</label>
                  <div className='d-flex'>
                    <input
                      className={`form-control ${errors.features?.[index] ? 'is-invalid' : ''}`}
                      key={field.id}
                      {...register(`features.${index}.name`, { required: true })}
                    />

                    <button className='btn btn-danger' style={{ marginLeft: '3px' }} onClick={() => remove(index)}>
                      <i class='bx bx-trash' ></i>
                    </button>
                  </div>
                </div>
              ))}

              <div className="col-lg-12 col-sm-12">
                <button
                  className="btn btn-primary mt-3"
                  type='button'
                  onClick={() => append({ feature: '' })}
                  style={{ width: '100%' }}>Add Feature&nbsp; <i class='bx bxs-plus-circle'></i>
                </button>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>
                <>
                  <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                  <button className="btn btn-warning" type="submit">Next Step</button>
                </>

              </div>
            </div>
          </form>


        </div>
      </div>
    </div>
  )
}

export default EntertainmentFeatureForm
