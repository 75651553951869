import React from 'react'

function Upload({register, errors}) {
  return (
    <>
      <div className="main active">
      <div className="d-flex">
                    <small><i className="bx bx-upload"></i></small><h2 className='mx-3'>Upload</h2>
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="file" name="vehicleDocs" {...register("vehicleDocs", { required: false })} />
                            <span>Vehicle Document </span>
                        </div>   
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="file" name="pollutionCertificate" {...register("pollutionCertificate", { required: false })} />
                            <span>Polllution Certificate </span>
                        </div>
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="file" name="featuredImage" {...register("featuredImage", { required: false })} />
                            <span>Featured Image </span>
                        </div> 
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="file" name="driverLicence" {...register("driverLicence", { required: false })} />
                            <span>Driver Licence </span>
                        </div>
                    </div>
                </div>
    </>
  )
}

export default Upload
