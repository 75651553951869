import React from 'react'
import './CateringProgressBar.scss'

function CateringProgressBar({stepNo}) {
    const step = stepNo;
    console.log("Props", step);
    return (


        <div style={{marginTop:'0px'}}>
            <div id="multistepsform" className='col-lg-8 col-md-8 mx-auto'>
                <ul id="progressbar">
                    {/* <li className={`${step===1 ? "active" : ""}`}>Category Selection</li> */}
                    <li className={`${step===1 ? "active" : ""}`}>Organization Details</li>
                    <li className={`${step===2 ? "active" : ""}`}>Owner Details</li>
                    <li className={`${step===3 ? "active" : ""}`}>Features</li>
                    <li className={`${step===4 ? "active" : ""}`}>Uploads</li>
                    <li className={`${step===5 ? "active" : ""}`}>Summary</li>
                </ul>
            </div>
        </div>


    )
}

export default CateringProgressBar
