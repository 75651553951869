import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import rootReducer from '../reducer/rootReducer';
import entertainmentSaga from '../saga/entertainmentSaga';

import makeupSaga from '../saga/makeupSaga';
import multiStepForm from '../saga/multiStepForm';
import transportSaga from '../saga/transportSaga';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["multiStepForm"] // only navigation will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: persistedReducer,
    middleware: () => [sagaMiddleware]
});

function* rootSaga() {
    return yield all([
        transportSaga,
        makeupSaga,
        entertainmentSaga,
        multiStepForm
    ]);
}
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store)

export {
    store,
    persistor
}