import React from 'react'
import CateringProgressBar from './CateringProgressBar'
import CateringOrgDetailsForm from './CateringOrgDetailsForm';
import CateringOwnerDetailsForm from './CateringOwnerDetailsForm';
import CateringFeatureForm from './CateringFeatureForm';
import CateringUploadsForm from './CateringUploadsForm';
import CateringSummaryForm from './CateringSummaryForm';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AdsCategory, AdsStepType } from '../../../../constants';
import { setFormDataAction, setStepAction } from '../../../../redux/action/multiStepFormAction';

function CateringCommon() {
  const currentStep = useSelector(state => state.multiStepForm.step);
  const dispatch = useDispatch();

  function setFormFilledData(data, type) {
    dispatch(setFormDataAction({
      type: AdsCategory.catering,
      data: { [type]: data }
    }))
  }

  function setStep(step) {
    console.log("PASSED Step FROM CHILD IS ==" + step);
    dispatch(setStepAction(step));
  }

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return <CateringOrgDetailsForm
          type={AdsStepType.organization}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />

      case 2:
        return <CateringOwnerDetailsForm
          type={AdsStepType.owner}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />

      case 3:
        return <CateringFeatureForm
          type={AdsStepType.features}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 4:
        return <CateringUploadsForm
          type={AdsStepType.uploads}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 5:
        return <CateringSummaryForm
          setStep={setStep} />
      default:
        return null;
    }
  };
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div style={{ marginLeft: '15%' }}>
              <CateringProgressBar stepNo={currentStep} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {
              getForm()
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default CateringCommon
