import React from 'react'

function Summary() {
  return (
    <>
      <div className="main active">
      <div className="d-flex">
                    <small><i className="bx bx-info-circle"></i></small><h2 className='mx-3'>Summary</h2>
                    </div>
                     {/* <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                         <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                     
                    <div className="text congrats">
                        <h2>Congratulations!</h2>
                        <p>Thanks Mr./Mrs. <span className="shown_name"></span> your information have been submitted successfully for the future reference we will contact you soon.</p>
                    </div> */}
                </div>
    </>
  )
}

export default Summary
