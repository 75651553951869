import { ENTERTAINMENT_LIST, ENTERTAINMENT_STEP_FIVE, ENTERTAINMENT_STEP_FOUR, ENTERTAINMENT_STEP_ONE, ENTERTAINMENT_STEP_THREE, ENTERTAINMENT_STEP_TWO, MAKEUP_LIST, SEARCH_SERVICE, TRANSPORT_LIST } from "../constant"


export const entertainmentList = () => {
  
    return {
        type: ENTERTAINMENT_LIST,
    }
}

export const entertainmentOrgDetails = (data) => {
  
    return {
        type: ENTERTAINMENT_STEP_ONE,
        payload: data
    }
}

export const entertainmentOwnerDetails = (data) => {
  
    return {
        type: ENTERTAINMENT_STEP_TWO,
        payload: data
    }
}

export const entertainmentFeatures = (data) => {
  
    return {
        type: ENTERTAINMENT_STEP_THREE,
        payload: data
    }
}
export const entertainmentUploads = (data) => {
  
    return {
        type: ENTERTAINMENT_STEP_FOUR,
        payload: data
    }
}

export const entertainmentSummary = (data) => {
  
    return {
        type: ENTERTAINMENT_STEP_FIVE,
        payload: data
    }
}

export const serviceSearch = (query) => {
  
    return {
        type: SEARCH_SERVICE,
        query
    }
}