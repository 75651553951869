import React from 'react'
import { Outlet } from 'react-router-dom'
import DashboardHeader from '../dashboard/DashboardHeader'
import DashboardSidebar from '../dashboard/DashboardSidebar'

function DashboardLayout() {
  return (
    <div className='container-fluid'>
      <DashboardHeader />
      <div className='row'>
        <div className='col-lg-2 col-md-2'>
        <DashboardSidebar />
        </div>
        <div className='col-lg-10 col-md-10 mx-auto' style={{marginTop:'6%'}}>
        <Outlet />
        </div>
        
      </div>
      
      
    </div>
  )
}

export default DashboardLayout
