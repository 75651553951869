import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'



function AddSubCategory() {

  const myHeaders = new Headers();

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [response, setResponse] = useState("");
    const [failedResponse, setFailedResponse] = useState("");
    const [categories, setCategories] = useState([]);
    

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const userToken = localStorage.getItem('loginToken');
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${userToken}`
      }
    };

  const onSubmit = (requestedData) => { 
    
    const requestData = {
      "subCategoryName":requestedData.subCategoryName,
      "subCategoryLabel":requestedData.subCategoryLabel,
      "shortDescription":requestedData.shortDescription,
      "category":[requestedData.category]
  }
  console.log("Requested data   ",requestData);
      setResponse("");
      setFailedResponse("");
      
     
     // return;
      //console.log("HEADER IS ===",axios.defaults.headers.common["Authorization"]);
    axios.post(baseUrl+"/api/v1/admin/subcategory/add", requestData, axiosConfig).then((res) =>{
        console.log("Response for fetchdata",res.data.msg);
        console.log("Response for fetchdata",res.data.status);
        if(res.data.status == 'OK'){
            setResponse(res.data.msg);
        }else{
            setFailedResponse(res.data.msg) 
        }
        
       // console.log(baseUrl,"BASE URL")
       }).catch(err =>{
         console.log('Exception ==='+err);
       });
  }

  const getAllCategory = ()=>{

    axios.get(baseUrl+"/api/v1/admin/category/list", axiosConfig).then((res) =>{
      console.log("Response for fetchdata",res.data);
      console.log("Response for fetchdata",res.data.status);
      if(res.data.status === 'OK'){
          setCategories(res.data.data);
          console.log("CATEGORIES IS ==", categories);
      }else{
          setFailedResponse(res.data.msg) 
      }
      
     // console.log(baseUrl,"BASE URL")
     }).catch(err =>{
       console.log('Exception ==='+err);
     });
  }

  // const categoryChanged = (event) =>{
  //   console.log('Category changed   '+event.target.value);
  //   setSelectedOption(event.target.value);
  // }

  useEffect  (()=>{
   getAllCategory();
  },[])
  return (
    
    
    <div className="col-lg-8 col-md-8 mx-auto" style={{marginTop:"7%"}}>
                  <div className="card-body p-4 p-lg-5 text-black" style={{backgroundColor:"#fff"}}>
                  <p className="text-center">ADD SUB CATEGORY</p>
                  {response.length > 0 ? 
                  <div class="alert alert-success" role="alert">
                      {response}
                   </div>:""
                   }
                    {failedResponse.length > 0 ? 
                  <div class="alert alert-danger" role="alert">
                      {failedResponse}
                   </div>:""
                   }

                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Sub Category Name</label>
                        <input type="text" name="subCategoryName" className="form-control form-control-md" {...register("subCategoryName", { required: true })}/>
                        {errors.subCategoryName && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Sub Category Label</label>
                        <input type="text" name="subCategoryLabel" className="form-control form-control-md" {...register("subCategoryLabel", { required: true })}/>
                        {errors.subCategoryLabel && <span className="form-error-dk">This field is required</span>}
                      </div>

                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Short Description</label>
                        <textarea name="shortDescription" className="form-control form-control-md" {...register("shortDescription", { required: true })}/>
                        {errors.shortDescription && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Select Category</label>
                        <select name="category" className="form-control form-control-md" {...register("category", { required: true })} >
                         <option value="">Select Category</option>

                         {
                         categories.map((res)=>{
                             return(
                                 <option value={res.id} key={res.id}>{res.categoryLabel}</option>
                             )
                         })}
                        </select>
                        {errors.category && <span className="form-error-dk">This field is required</span>}
                      </div>
    
                      <div className="col-md-12 col-lg-12 d-flex ">
                      <div className="pt-1 mb-4">
                        <button className="btn btn-dark btn-lg btn-block" type="submit">Add</button>
                      </div>
                     
                      </div>
                     
                    </form>
    
                  </div>
                </div>
    
  )
}

export default AddSubCategory
