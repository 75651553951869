import React, { useEffect, useState } from 'react'
import MakeupProgressBar from './MakeupProgressBar'
import MakeupOrgDetailsForm from './OrganizationDetailsForm';
import MakeupOwnerDetailsForm from './MakeupOwnerDetailsForm';
import MakeupFeatureForm from './MakeupFeatureForm';
import MakeupUploadsForm from './MakeupUploadsForm';
import MakeupSummaryForm from './MakeupSummaryForm';
import { useDispatch, useSelector } from 'react-redux';
import { setFormDataAction, setStepAction } from '../../../../redux/action/multiStepFormAction';
import { AdsCategory, AdsStepType } from '../../../../constants';

function MakeupCommon() {

  const currentStep = useSelector(state => state.multiStepForm.step);
  const dispatch = useDispatch();

  function setFormFilledData(data, type) {
    dispatch(setFormDataAction({
      type: AdsCategory.makeupAndGrooming,
      data: { [type]: data }
    }))
  }
  const [orgState, setOrgState] = useState({});
  const [ownerState, setOwnerState] = useState({});
  const [featureState, setFeatureState] = useState([{}]);
  const [uploadState, setUploadState] = useState({});

  const [makeupState, setMakeupState] = useState({
    organization: '',
    owner: '',
    features: [{}],
    uploads: ''
  })

  const [featuredImageState, setFeaturedImageState] = useState(null);
  const [galleryImageState, setGalleryImageState] = useState(null);

  function getOrgData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setOrgState(data);
  }

  function getOwnerData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setOwnerState(data);
  }

  function getFeatureData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setFeatureState(data);
  }

  function getUploadedData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setUploadState({
      featuredImage: data.featuredImage.name,
      gallery: [data.gallery.name]
    });

    setFeaturedImageState(data.featuredImage);
    setGalleryImageState(data.gallery);

  }


  function getSummaryData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setMakeupState(
      {
        organization: orgState,
        owner: ownerState,
        // features:featureState,
        uploads: uploadState
      }
    );
  }

  // function getStep(step) {
  //   console.log("PASSED Step FROM CHILD IS ==" + step);
  //   // setCurrentStep(step);
  // }

  // if(step != null){
  //   setCurrentStep(step);
  //   console.log("PASSED OBJECT IS =="+orgObject)
  // }


  useEffect(() => {
    setMakeupState(
      {
        organization: orgState,
        owner: ownerState,
        // features:featureState,
        uploads: uploadState
      }
    );
  }, [orgState, ownerState, featureState, uploadState])

  function setStep(step) {
    console.log("PASSED Step FROM CHILD IS ==" + step);
    dispatch(setStepAction(step));
  }

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return <MakeupOrgDetailsForm
          type={AdsStepType.organization}
          setStep={setStep}
          setFormFilledData={setFormFilledData}
        />
      case 2:
        return <MakeupOwnerDetailsForm
          type={AdsStepType.owner}
          setStep={setStep}
          setFormFilledData={setFormFilledData}
        />
      case 3:
        return <MakeupFeatureForm
          type={AdsStepType.features}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 4:
        return <MakeupUploadsForm
          type={AdsStepType.uploads}
          setStep={setStep}
          setFormFilledData={setFormFilledData}
        />
      case 5:
        return <MakeupSummaryForm
          setStep={setStep} />
      default:
        return null;
    }
  }
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div style={{ marginLeft: '15%' }}>
              <MakeupProgressBar stepNo={currentStep} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {/* <form enctype="multipart/form-data"> */}
            {
              getForm()
            }

          </div>

        </div>
      </div>
    </div>
  )
}

export default MakeupCommon
