import React from 'react'
import './Dashboard.css'

export default function DashboardHome() {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12 col-md-12'>
          <ul className='totalBox'>
            <li>
              <div className='innerBox'>
               <p style={{textAlign:'center', padding:'5%', fontSize:'.8rem', marginBottom:'0rem'}}>Transports</p>
               <p style={{textAlign:'center', padding:'0%', fontSize:'2rem', marginBottom:'0rem'}}>23408</p>
              </div>
            </li>
            <li>
              <div className='innerBox'>
               <p style={{textAlign:'center', padding:'5%', fontSize:'.8rem', marginBottom:'0rem'}}>Entertainments</p>
               <p style={{textAlign:'center', padding:'0%', fontSize:'2rem', marginBottom:'0rem'}}>26408</p>
              </div>
            </li>
            <li>
              <div className='innerBox'>
               <p style={{textAlign:'center', padding:'5%', fontSize:'.8rem', marginBottom:'0rem'}}>Hotels</p>
               <p style={{textAlign:'center', padding:'0%', fontSize:'2rem', marginBottom:'0rem'}}>23408</p>
              </div>
            </li>
            <li>
              <div className='innerBox'>
               <p style={{textAlign:'center', padding:'5%', fontSize:'.8rem', marginBottom:'0rem'}}>Beauty & Grooming</p>
               <p style={{textAlign:'center', padding:'0%', fontSize:'2rem', marginBottom:'0rem'}}>23408</p>
              </div>
            </li>
            <li>
              <div className='innerBox'>
               <p style={{textAlign:'center', padding:'5%', fontSize:'.8rem', marginBottom:'0rem'}}>Catering</p>
               <p style={{textAlign:'center', padding:'0%', fontSize:'2rem', marginBottom:'0rem'}}>23408</p>
              </div>
            </li>
            <li>
              <div className='innerBox'>
               <p style={{textAlign:'center', padding:'5%', fontSize:'.8rem', marginBottom:'0rem'}}>Total</p>
               <p style={{textAlign:'center', padding:'0%', fontSize:'2rem', marginBottom:'0rem'}}>23408</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
