import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header'

function HomePageLayout() {

    let isLoggedIn = false;
    const authToken = localStorage.getItem("loginToken");
    console.log("autoken is==="+authToken);
    if(authToken != null){
      isLoggedIn = true;
    }
  return (
    <>
      <Header isUserLoggedIn={isLoggedIn} />
      <div className='container-fluid' style={{paddingLeft:'0px', paddingRight:'0px'}}>
      <>
      <Outlet />
      </>
      </div>
      <Footer />
    </>
  )
}

export default HomePageLayout
