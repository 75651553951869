import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../../../utils/Pagination';


function MyServices() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  const userToken = localStorage.getItem('loginToken');

  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${userToken}`
    }
  };

const getAllCategory = ()=>{

  axios.get(baseUrl+"/api/v1/admin/category/list", axiosConfig).then((res) =>{
    console.log("Response for fetchdata",res.data);
    console.log("Response for fetchdata",res.data.status);
    if(res.data.status === 'OK'){
        setCategories(res.data.data);
        console.log("CATEGORIES IS ==", categories);
    }else{
       // setFailedResponse(res.data.msg) 
    }
   }).catch(err =>{
     console.log('Exception ==='+err);
   });
}



    const deleteCategory = (item)=>{
     console.log(item);
     //confirm();
     axios.put(baseUrl+"/api/v1/admin/category/delete", axiosConfig).then((res) =>{
      console.log("Response for fetchdata",res.data);
      console.log("Response for fetchdata",res.data.status);
      if(res.data.status === 'OK'){
          setCategories(res.data.data);
          console.log("CATEGORIES IS ==", categories);
      }else{
         // setFailedResponse(res.data.msg) 
      }
     }).catch(err =>{
       console.log('Exception ==='+err);
     });
    }

    const updateCategory = (item) =>{
        console.log(item,"jjj");
        //  <EditBrand data={item} />

        const encodedModelId = btoa(item.modelId);
         let updatePath = `/dashboard/edit-model/${encodedModelId}`;
         navigate(updatePath);
        console.log(item);
       }

       useEffect  (()=>{
        getAllCategory();
       },[])
         
  return (
    <div className='row'>
        <div className="col-lg-12 col-md-12 mx-auto" style={{backgroundColor:"#fff", minHeight:'90vh'}}>
      <table className="table table-bordered">
        <thead>
            <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>Label</th>
                <th>Created At</th>
                 <th>Action</th>
                
            </tr>
        </thead>
        <tbody>
            {categories.length > 0 ? categories.map((item, index)=>{
                return(
                    <tr key={index}>
                <td>{index+1}</td>
                <td>{item.categoryName}</td>
                <td>{item.categoryLabel}</td>
                <td>{item.createdAt}</td>
                <td>
                    <ul className='d-flex action-list'>
                        <li><button className='btn btn-primary' style={{padding: '0px 5px'}} onClick={() => updateCategory(item)}><i style={{fontSize:"1rem"}} className="bx bx-edit"></i></button></li>
                        <li><button className='btn btn-danger' style={{padding: '0px 5px'}} onClick={() => deleteCategory(item)}><i style={{ fontSize:"1rem"}} className="bx bx-trash"></i></button></li>
                    </ul>
                </td>
                
            </tr>
                )
            }):<tr>
           <td colSpan={5}>No data Available</td>
            </tr>
}  
            
           
           
        </tbody>
       
    </table>
    <Pagination initialPage="1" pageSize="1" totalData="10" />
    </div>
    </div>
  )
}

export default MyServices
