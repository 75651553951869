import React, { useState } from 'react'
import StarRating from './StarRating'
import { MdAdminPanelSettings, MdLocationOn, MdOutlineFormatListNumberedRtl } from "react-icons/md";
import { FaUserAlt } from 'react-icons/fa';
import QuoteModal from './QuoteModal';

function SingleSidebar({data, showModal}) {

   const openModal = () =>{

    showModal(true)
   }

    return (
        <div>
            <div className='col-lg-12 col-md-12 col-sm-12 bgColor' style={{ minHeight: '300px', marginBottom: '2%' }}>


                <h3 className='title'>{data.organization?.orgName}</h3>
                <div className="d-grid gap-2  mx-auto d-flex">
                    <button type="button" className="btn primary">
                        <span className="badge bg-dk px-2 py-2">4.6 </span>
                    </button>
                    <span><StarRating value="4.6" /></span>
                    <span className='review-span'><b>540</b> Reviews</span>
                </div>
                <ul className='service-desc px-0'>

                    <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Tooltip on left" title="Owner Name"><span><FaUserAlt /> </span>{data.owner?.ownerFirstName}&nbsp;{data.owner?.ownerLastName}</li>
                    {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Owner Mobile Number"><span><FaPhoneSquareAlt /> </span>{`${item.owner?.ownerMobileNo}`}</li> */}
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Colour"><span><MdAdminPanelSettings /> </span>{data?.vehicleColour}</li>
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Company"><span> <MdAdminPanelSettings /></span>{data?.brand}</li>
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Model"><span> <MdAdminPanelSettings /></span>{data?.model}</li>
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Model"><span> <MdAdminPanelSettings /></span>{data?.launchYear}</li>
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Model"><span> <MdAdminPanelSettings /></span>{data?.vehicleNo}</li>
                </ul>


            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 bgColor' style={{ minHeight: '150px', marginBottom: '2%' }}>
                <h3 className='title' style={{ textAlign: 'center', fontSize: '1.5 rem' }}>Owner Information</h3>
                <div className='name-img d-flex'>
                    <img src="https://statics.olx.in/external/base/img/avatar_4.png" width="80" className='img-fluid' alt="owner Image" />
                    <h4 style={{ paddingTop: '8%', fontSize: '1rem', fontWeight: 'bold' }}>{data.owner?.ownerFirstName}&nbsp;{data.owner?.ownerLastName}</h4>
                    {/* <p>Member since Oct 2022</p> */}
                </div>
                {/* <p>Click on below button and fill the form to get the call from him</p> */}

               
                <button className='btn btn-warning' style={{ padding: '1% 3%', margin: '5% 3%', width: '90%' }} onClick={openModal}>Get a Quote</button>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 bgColor' style={{ minHeight: '300px', marginBottom: '2%' }}>

            </div>

        </div>
    )
}

export default SingleSidebar
