import { AdsCategory } from "../../constants";
import { SET_CATEGORY, SET_FORM, SET_STEP, RESET_FORM } from "../action-types/multiStepFormTypes";


const initialState = {
    category: null,
    subCategory: null,
    step: 1,
    transport: {
        organization: {},
        vehicle: {},
        owner: {},
        driver: {},
        features: {},
        uploads: {},
    },
    entertainment: {
        organization: {},
        owner: {},
        features: {},
        uploads: {},
    },
    hotel: {
        organization: {},
        owner: {},
        features: {},
        uploads: {},
    },
    makeupAndGrooming: {
        organization: {},
        owner: {},
        features: {},
        uploads: {},
    },
    catering: {
        organization: {},
        owner: {},
        features: {},
        uploads: {},
    }
}
const multiStepForm = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY:
            return {
                ...state,
                category: action.payload.category,
                subCategory: action.payload.subCategory,
            }
        case SET_STEP:
            return {
                ...state,
                step: action.payload,
            }
        case SET_FORM:
            switch (action.payload.type) {
                case AdsCategory.transport:
                    return {
                        ...state,
                        transport: {
                            ...state.transport,
                            ...action.payload.data
                        }
                    }
                case AdsCategory.entertainment:
                    return {
                        ...state,
                        entertainment: {
                            ...state.entertainment,
                            ...action.payload.data
                        }
                    }
                case AdsCategory.hotel:
                    return {
                        ...state,
                        hotel: {
                            ...state.hotel,
                            ...action.payload.data
                        }
                    }
                case AdsCategory.makeupAndGrooming:
                    return {
                        ...state,
                        makeupAndGrooming: {
                            ...state.makeupAndGrooming,
                            ...action.payload.data
                        }
                    }
                case AdsCategory.catering:
                    return {
                        ...state,
                        catering: {
                            ...state.catering,
                            ...action.payload.data
                        }
                    }
                default:
                    return state
            }
        case RESET_FORM:
            return initialState
        default:
            return state
    }
}

export {
    multiStepForm
} 