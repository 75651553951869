function fileToBlob(file) {
    if (file && (file instanceof Blob || file instanceof MediaSource)) {
        return URL.createObjectURL(file);
    }
    return null
}

function gotPhoto(element, name) {
    const file = element.files[0];
    const reader = new FileReader()
    reader.onload = function (base64) {
        localStorage.setItem(name, base64);
    }
    reader.readAsDataURL(file);
}
// Saved to localstorage

function getPhoto() {
    const base64 = localStorage["file"];
    const base64Parts = base64.split(",");
    const fileFormat = base64Parts[0].split(";")[1];
    const fileContent = base64Parts[1];
    const file = new File([fileContent], "file name here", { type: fileFormat });
    return file;
}

export {
    fileToBlob
}