import { takeEvery, put } from 'redux-saga/effects'
import { SET_TRANSPORT_LIST, TRANSPORT_LIST } from '../constant';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

function* getTransports() {
    let data = yield fetch(baseUrl + "/api/v1/transport/list");
    data = yield data.json();
    data = data.data;
    console.log("action is called", data)
    yield put({type: SET_TRANSPORT_LIST, data})
}

function* transportSaga() {
    yield takeEvery(TRANSPORT_LIST, getTransports)
}

export default transportSaga;