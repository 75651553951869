export const ADD_TO_CART="ADD_TO_CART"
export const REMOVE_FROM_CART="REMOVE_FROM_CART"
export const EMPTY_CART="EMPTY_CART"
export const TRANSPORT_LIST="TRANSPORT_LIST"
export const SET_TRANSPORT_LIST="SET_TRANSPORT_LIST"
export const SEARCH_SERVICE="SEARCH_SERVICE"
export const MAKEUP_LIST="MAKEUP_LIST"
export const SET_MAKEUP_LIST="SET_MAKEUP_LIST"

export const ENTERTAINMENT_LIST="ENTERTAINMENT_LIST"
export const SET_ENTERTAINMENT_LIST="SET_ENTERTAINMENT_LIST"

/* ENTERTAINMENT MULTIFORM START*/

export const ENTERTAINMENT_STEP_ONE="ENTERTAINMENT_STEP_ONE"
export const SET_ENTERTAINMENT_STEP_ONE="SET_ENTERTAINMENT_STEP_ONE"

export const ENTERTAINMENT_STEP_TWO="ENTERTAINMENT_STEP_TWO"
export const SET_ENTERTAINMENT_STEP_TWO="SET_ENTERTAINMENT_STEP_TWO"

export const ENTERTAINMENT_STEP_THREE="ENTERTAINMENT_STEP_THREE"
export const SET_ENTERTAINMENT_STEP_THREE="SET_ENTERTAINMENT_STEP_THREE"

export const ENTERTAINMENT_STEP_FOUR="ENTERTAINMENT_STEP_FOUR"
export const SET_ENTERTAINMENT_STEP_FOUR="SET_ENTERTAINMENT_STEP_FOUR"

export const ENTERTAINMENT_STEP_FIVE="ENTERTAINMENT_STEP_FIVE"
export const SET_ENTERTAINMENT_STEP_FIVE="SET_ENTERTAINMENT_STEP_FIVE"

/* ENTERTAINMENT MULTIFORM END*/

/* HOTEL MULTIFORM START*/

export const HOTEL_STEP_ONE="HOTEL_STEP_ONE"
export const SET_HOTEL_STEP_ONE="SET_HOTEL_STEP_ONE"

export const HOTEL_STEP_TWO="HOTEL_STEP_TWO"
export const SET_HOTEL_STEP_TWO="SET_HOTEL_STEP_TWO"

export const HOTELSTEP_THREE="HOTELSTEP_THREE"
export const SET_HOTEL_STEP_THREE="SET_HOTEL_STEP_THREE"

export const HOTEL_STEP_FOUR="HOTEL_STEP_FOUR"
export const SET_HOTEL_STEP_FOUR="SET_HOTEL_STEP_FOUR"

export const HOTEL_STEP_FIVE="HOTEL_STEP_FIVE"
export const SET_HOTEL_STEP_FIVE="SET_HOTEL_STEP_FIVE"

/* HOTEL MULTIFORM END*/

