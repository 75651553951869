const AdsCategory = {
    transport: "transport",
    makeupAndGrooming: "makeupAndGrooming",
    entertainment: "entertainment",
    hotel: "hotel",
    catering: "catering"
}
const AdsStepType = {
    organization: "organization",
    company: "company",
    vehicle: "vehicle",
    owner: "owner",
    driver: "driver",
    features: "features",
    uploads: "uploads",
    summary: "summary",
    hotel: "hotel"
}
const CategoryIds = {
    transport: 1,
    makeupAndGrooming: 3,
    entertainment: 7,
    hotel: 8,
    catering: 9,
}
const AdPostformsType = {
    [CategoryIds.transport]: [AdsStepType.organization, AdsStepType.vehicle, AdsStepType.owner, AdsStepType.driver, AdsStepType.features, AdsStepType.uploads, AdsStepType.summary],
    [CategoryIds.makeupAndGrooming]: ["organization", "owner", "features", "uploads", "summary"],
    [CategoryIds.entertainment]: ["company", "owner", "features", "uploads", "summary"],
    [CategoryIds.hotel]: ["hotel", "owner", "features", "uploads", "summary"],
    [CategoryIds.catering]: ["organization", "owner", "features", "uploads", "summary"],
}

function getQueryParams(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] === variable) { return pair[1]; }
    }
    return "";
}
export {
    AdsCategory,
    AdsStepType,
    CategoryIds,
    AdPostformsType,
    getQueryParams
}