import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { useHeaderConfig } from '../../../../hooks/useHeaderConfig';


function AddCategory() {

  const myHeaders = new Headers();

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [response, setResponse] = useState("");
    const [failedResponse, setFailedResponse] = useState("");
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = (requestedData) => { 
    console.log("Requested data   ",requestedData);
      setResponse("");
      setFailedResponse("");
      
      const userToken = localStorage.getItem('loginToken');
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${userToken}`
        }
      };
     // return;
      //console.log("HEADER IS ===",axios.defaults.headers.common["Authorization"]);
    axios.post(baseUrl+"/api/v1/admin/category/add", requestedData, axiosConfig).then((res) =>{
        console.log("Response for fetchdata",res.data.msg);
        console.log("Response for fetchdata",res.data.status);
        if(res.data.status == 'OK'){
            setResponse(res.data.msg);
        }else{
            setFailedResponse(res.data.msg) 
        }
        
       // console.log(baseUrl,"BASE URL")
       }).catch(err =>{
         console.log('Exception ==='+err);
       });
  }
  return (
    
    
    <div className="col-lg-8 col-md-8 mx-auto" style={{marginTop:"7%"}}>
                  <div className="card-body p-4 p-lg-5 text-black" style={{backgroundColor:"#fff"}}>
                  <p className="text-center">ADD CATEGORY</p>
                  {response.length > 0 ? 
                  <div class="alert alert-success" role="alert">
                      {response}
                   </div>:""
                   }
                    {failedResponse.length > 0 ? 
                  <div class="alert alert-danger" role="alert">
                      {failedResponse}
                   </div>:""
                   }

                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Category Name</label>
                        <input type="text" name="categoryName" className="form-control form-control-md" {...register("categoryName", { required: true })}/>
                        {errors.categoryName && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Category Label</label>
                        <input type="text" name="categoryLabel" className="form-control form-control-md" {...register("categoryLabel", { required: true })}/>
                        {errors.categoryLabel && <span className="form-error-dk">This field is required</span>}
                      </div>

                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Short Description</label>
                        <textarea name="shortDescription" className="form-control form-control-md" {...register("shortDescription", { required: true })}/>
                        {errors.shortDescription && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                    
    
                      <div className="col-md-12 col-lg-12 d-flex ">
                      <div className="pt-1 mb-4">
                        <button className="btn btn-dark btn-lg btn-block" type="submit">Add</button>
                      </div>
                     
                      </div>
                     
                    </form>
    
                  </div>
                </div>
    
  )
}

export default AddCategory
