import React from 'react'

function OrganizationDetails({register, errors}) {
  return (
    <>
       <div className="main active">
      
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name="orgName" {...register("orgName", { required: true })} />
                            <span>Organization Name*</span>
                        </div>
                        <div className="input-div"> 
                            <input type="text" name="gstNo" />
                            <span>GST Number</span>
                        </div>
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name="officeAddress" {...register("officeAddress", { required: true })}/>
                            <span>Office Address</span>
                        </div>
                        <div className="input-div">
                            <input type="number" name="officeContactNo" {...register("officeContactNo", { required: true })} />
                            <span>Office Contact Number</span>
                        </div>
                        
                    </div>
            
                </div>
    </>
  )
}

export default OrganizationDetails
