import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AdsCategory, AdsStepType } from '../../../../constants';
import { setFormDataAction, setStepAction } from '../../../../redux/action/multiStepFormAction';

import CompanyDetailsForm from './CompanyDetailsForm';
import DriverDetailsForm from './DriverDetailsForm';
import FeatureForm from './FeatureForm';
import OwnerDetailsForm from './OwnerDetailsForm';
import ProgressBar from './ProgressBar';
import SummaryForm from './SummaryForm';
import UploadsForm from './UploadsForm';
import VehicleDetailsForm from './VehicleDetailsForm';


function TransportCommon() {

  const currentStep = useSelector(state => state.multiStepForm.step);
  const dispatch = useDispatch();

  function setFormFilledData(data, type) {
    dispatch(setFormDataAction({
      type: AdsCategory.transport,
      data: { [type]: data }
    }))
  }

  function setStep(step) {
    console.log("PASSED Step FROM CHILD IS ==" + step);
    dispatch(setStepAction(step));
  }

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return <CompanyDetailsForm
          type={AdsStepType.organization}
          setStep={setStep}
          setFormFilledData={setFormFilledData}
        />
      case 2:
        return <VehicleDetailsForm
          type={AdsStepType.vehicle}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 3:
        return <OwnerDetailsForm
          type={AdsStepType.owner}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 4:
        return <DriverDetailsForm
          type={AdsStepType.driver}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 5:
        return <FeatureForm
          type={AdsStepType.features}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 6:
        return <UploadsForm
          type={AdsStepType.uploads}
          setStep={setStep}
          setFormFilledData={setFormFilledData}
        />
      case 7:
        return <SummaryForm
          setStep={setStep} />
      default:
        return null
    }
  }
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <ProgressBar stepNo={currentStep} />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {
              getForm()
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default TransportCommon
