import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import logoImg from '../../../images/bws-logo.png';

export default function SpDashboardHeader() {

  const navigate = useNavigate();
  const loginToken = localStorage.getItem('loginToken');
    
    const [token, setToken] = useState(loginToken);

  const logout = () =>{
    console.log("logged out");
    if(loginToken != null){
        localStorage.removeItem('loginToken');
        navigate("/");
        setToken(null);
        
    }
}
  return (
    <>
      <div className="row fixed-top" style={{backgroundColor:"#5f37d7"}}>
            <div className="col-lg-6 col-md-6">
                <nav className="navbar navbar-expand-xl navbar-light">
                    <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                                        <span className='logo-text'>Shadi<i>material</i></span>
                                    </Link >
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown2">
                            <ul className="navbar-nav mx-auto">
                               
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" style={{color:"#fff"}}>Home</Link>
                                </li>
                               
                              
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="col-lg-4 col-md-3">
               {/* <SearchBar /> */}
            </div>

            <div className="col-lg-2 col-md-3 cart-login">
                {token ?
   <div className="float-end cart mt-0">

   <ul className="nav flex-row text-white">
       {/* <li href="#" className="nav-link" >
       <button type="button" class="btn btn-warning position-relative" style={{marginTop:'-7%'}}>
              
                <span>Post Ad</span>
           </button>
       </li> */}
       <li href="#" className="nav-link">
           <button type="button" class="btn btn-default position-relative" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{paddingTop:'11%'}}>
               <i className="bx bxs-bell"></i>
               <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                   99+
                   <span class="visually-hidden">unread messages</span>
               </span>
           </button>
           <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
               <div class="offcanvas-header">
                   <h5 class="offcanvas-title" id="offcanvasRightLabel">Notifications</h5>
                   <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
               </div>
               <div class="offcanvas-body">
                   ...
               </div>
           </div>
       </li>

       <li className="dropdown">
           <button type="button" class="btn btn-default position-relative" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true" style={{ color: '#fff' }}>
               <i className="bx bxs-user btn btn-default" style={{ color: '#fff' }} ></i>
           </button>

           <ul className="dropdown-menu user-menu" aria-labelledby="dropdownMenuButton1">
               <li><a className="dropdown-item" href="#">Hi, Deepak</a></li>
               <li><a className="dropdown-item" href="#">Profile</a></li>
               <li><a className="dropdown-item" href="javascript:void(0)" onClick={logout}>Logout</a></li>
           </ul>

       </li>
   </ul>


</div> :''
                }   
           
            </div>
   
        </div>
    </>
  )
}
