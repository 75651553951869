import React, { useState }  from "react";
import { Link } from "react-router-dom";
import logoImg from '../images/logo.jpeg'
import bgImg from '../images/bg.jpg';
import loginImg from '../images/weding-login.webp'
import lockIcon from '../images/login-icon.jpg'
import { useForm } from "react-hook-form";
import './css/Signup.css';
import axios from 'axios'
import { useNavigate } from "react-router-dom";

import jwt from 'jwt-decode'

const Login = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [errorContent, setErrorContent] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userDetails, setUserDetail] = useState("");

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = (data) => { 
    setErrorContent("");
    setFormSubmitted(true);
    const postData = {
     
      userName: data.email,
      password: data.password,
      
    }
    console.log(postData);
    axios.post(baseUrl + "/authenticate", postData).then((res) => {
      
      console.log("Response for fetchdata", res);
      if (res.status == 200) {
        localStorage.setItem("loginToken", res.data.jwtToken);
        setFormSubmitted(false);
        const token = localStorage.getItem('loginToken');
        if(token){
         // setAuthToken(token);
         // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        // console.log('axios.defaults.headers.common["Authorization"] ====', axios.defaults.headers.common["Authorization"]);
          const userToken = jwt(token);
          console.log('User details is', userToken.sub)
          userDetailsFunc(userToken.sub);
          navigate("/dashboard");
        }else{
          //delete axios.defaults.headers.common["Authorization"];
        }
       
      } else {
         // setFailedResponse(res.data.msg)
         setErrorContent(res.statusCode);
         setFormSubmitted(false);
      }

  }).catch(err => {
     setErrorContent("Something went wrong");
      console.log("ERROR    ", err);
      console.log("BASE URL    ", baseUrl);
      setFormSubmitted(false);
  });
  }
    const userDetailsFunc = (userEmail) =>{

     // setAuthToken(localStorage.getItem('jwtToken'));
      axios.post(baseUrl + `/api/v1/auth/user/details?email=${userEmail}`).then((res) => {
      
        console.log("Response for fetchdata", res);
        if (res.status == 200) {
          setUserDetail(res.data.data);
        } 
        else {
           // setFailedResponse(res.data.msg)
           setErrorContent(res.statusCode);
           setFormSubmitted(false);
        }
  
    }).catch(err => {
       setErrorContent("Something went wrong");
        console.log("ERROR    ", err);
        console.log("BASE URL    ", baseUrl);
        setFormSubmitted(false);
    });
    }

    const useEffect = (() =>{
      console.log("UseEffect Called ",userDetails);
    }, [userDetails])
  const myStyle={
    backgroundImage: 
    "url('../images/bg1.jpg')",
    height:'100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
    return (
     

           <div className="container">
            <div className="row">
            <div className="col-lg-8 col-md-8 mx-auto" style={{marginTop:'5%'}}>
              <div className="card" style={{borderRadius: "1rem"}}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                      alt="login form" className="img-fluid" style={{borderRadius: "1rem 0 0 1rem"}}/> */}
                      <img src={loginImg}
                      alt="login form" className="img-fluid" style={{borderRadius: "1rem 0 0 1rem", height:'100%'}}/>
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  
                    <div className="card-body p-4 p-lg-5 text-black">
                    
                    <p className="text-center">
                    <i style={{color:"#333", fontSize:"4rem"}} className="bx bx-lock"></i>
                    {errorContent.length > 0 ? 
                  <div className="alert alert-danger" role="alert">
                      {errorContent}
                   </div>:""
                   }
                    </p>
                      <form noValidate onSubmit={handleSubmit(onSubmit)}>
                      {/* <img src={lockIcon}  className="img-fluid" alt="login form" style={{width:"10vh", height:"auto"}}/> */}
                        <div className="form-outline mb-2 ">
                        <label className="form-label-dk" htmlFor="form2Example17">Email address</label>
                          <input type="email" name="email" id="form2Example17" className="form-control form-control-md" {...register("email", { required: true })}/>
                          {errors.email && <span className="form-error-dk">This field is required</span>}
                        </div>
                        
                        
      
                        <div className="form-outline mb-2">
                        <label className="form-label-dk" htmlFor="form2Example27">Password</label>
                          <input type="password" name="password" id="form2Example27" className="form-control form-control-md" {...register("password", { required: true })}/>
                          {errors.password && <span className="form-error-dk">This field is required</span>}
                        </div>
      
                        <div className="col-md-12 col-lg-12 d-flex ">
                        <div className="pt-1 mb-4">
                          <button className="btn btn-dark btn-lg btn-block" type="submit" disabled={formSubmitted}>Login</button>
                        </div>
                        <div className="pt-1 mb-2 mx-2">
                        <a className="small text-muted" href="#!">Forgot password?</a>
                        <p className="mb-1 pb-md-2" style={{color: "#393f81"}}>Don't have an account? <Link to="/signup" className='loginText'>Signup</Link></p>
                        </div>
                        </div>
                       
                      </form>
      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
           </div>
         
    );
}

export default Login;