import React, {useState} from 'react'
import { FaStar } from 'react-icons/fa';

import './util.css';

export default function StarRating() {

    const [rating, setRating] = useState(null);
  return (
    <div>
        {[...Array(5)].map((star, i )=>{

const ratingValue = i+1;
            return (<label>
                <input type="radio" key={i} name='rating' value={ratingValue} onClick={()=>setRating(ratingValue)} style={{display:"none"}}/>
                <FaStar size={20} className="star" color={ratingValue <= rating ? "#ffc107" : "#e4e5e9"}/>
                </label>)
        })}
      
    </div>
  )
}
