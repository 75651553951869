import React from 'react'
import './../components/css/Loader.css'

function Loader() {

    const styles = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '1000',
    top: '40%',
    left: '0px',
    opacity: '0.5',
    filter: 'alpha(opacity=50)'
    }
  return (
    <div>
    
    {/* <button class="btn btn-primary" disabled>
    <span class="spinner-border spinner-border-sm"></span>
    Loading..
  </button> */}
  <div class="title-container">
		<div className="spinner">
			<svg className='svg' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				<circle cx="50" cy="50" r="46" />
			</svg>
		</div>
		<h1 class="loader-title">loading...</h1>
	</div>

  {/* <div style= {styles}>
      <div className="d-flex justify-content-center">  
        <div className="spinner-grow text-primary" role="status" style={{width: '3rem', height: '3rem', zIndex: '20'}}>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div> */}
    </div>
  )
}

export default Loader
