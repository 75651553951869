import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import Login from './components/Login';
import Header from './components/Header'
import About from './components/About';
import Dashboard from './components/dashboard/DashboardHome';



import Home from './components/Home';
import Vehicle from './components/listing/Transports';
import DashboardLayout from './components/layouts/DashboardLayout';
import DashboardHome from './components/dashboard/DashboardHome';
import FrontLayout from './components/layouts/FrontLayout';
import Signup from './components/Signup';

import CommonVehicleFile from './components/dashboard/pages/vehicle/multiStep/CommonVehicleFile';
import AddBrand from './components/dashboard/pages/vehicle/brand/AddBrand';
import EditBrand from './components/dashboard/pages/vehicle/brand/EditBrand';
import AddModel from './components/dashboard/pages/vehicle/model/AddModel';
import ModelList from './components/dashboard/pages/vehicle/model/ModelList';
import BrandList from './components/dashboard/pages/vehicle/brand/BrandList';
import SinglePage from './components/SinglePage';
import HomePageLayout from './components/layouts/HomePageLayout';
import AddCategory from './components/dashboard/pages/admin/AddCategory';
import AddSubCategory from './components/dashboard/pages/admin/AddSubCategory';
import CategoryList from './components/dashboard/pages/admin/CategoryList';
import SubCategoryList from './components/dashboard/pages/admin/SubCategoryList';
import ServiceProviderLayout from './components/layouts/ServiceProviderLayout';
import AdPost from './components/service-provider/ad/AdPost';
import CategoryForm from './components/service-provider/ad/CategoryForm';
import Transports from './components/listing/Transports';
import MakeAndGrooming from './components/listing/MakeupAndGrooming';
import Entertainment from './components/listing/Entertainment';
import Hotels from './components/listing/Hotels';
import CateringServices from './components/listing/CateringServices';
import MyServices from './components/service-provider/sp-dashboard/pages/MyServices';
import SpDashboardHome from './components/service-provider/sp-dashboard/SpDashboardHome';
import TransportList from './components/dashboard/pages/services/TransportList';
import EditTransportCommon from './components/service-provider/update/transport/EditTransportCommon';


function App() {
  
  
  return (
    <>
  <BrowserRouter>
     <Routes>
     <Route exact path="/" element={<HomePageLayout />} >
     <Route index element={<Home />} />
      </Route>
     <Route exact path="/" element={<FrontLayout />} >
             
             <Route path="login" element={<Login />} />
             <Route path="signup" element={<Signup />} />
             <Route path="category/transports" element={<Transports />} />
             <Route path="category/makeup-and-grooming" element={<MakeAndGrooming />} />
             <Route path="category/entertainment" element={<Entertainment />} />
             <Route path="category/hotels" element={<Hotels />} />
             <Route path="category/catering-services" element={<CateringServices />} />
             <Route path="single/:type/:id" element={<SinglePage />} />
             <Route path="sp/category/add" element={<CategoryForm />} />
             <Route path="sp/register/ad" element={<AdPost />} />
             

           </Route>

           <Route exact path="/dashboard" element={<DashboardLayout />} >
             <Route index element={<DashboardHome />} />
             <Route path="add-brand" element={<AddBrand />} />
             <Route path="edit-brand/:id" element={<EditBrand />} />
             <Route path="brand-list" element={<BrandList />} />


             <Route path="add-model" element={<AddModel />} />
             <Route path="model-list" element={<ModelList />} />
             
             {/* <Route path="vehicle/add" element={<AddVehicle />} /> */}
             <Route path="vehicle/add" element={<CommonVehicleFile />} />
             

             <Route path="admin/category/add" element={<AddCategory />} />
             <Route path="admin/sub-category/add" element={<AddSubCategory />} />
             <Route path="admin/category/list" element={<CategoryList />} />
             <Route path="admin/sub-category/list" element={<SubCategoryList />} />
             <Route path="transport-list" element={<TransportList />} />
             <Route path="edit-transport/:id" element={<EditTransportCommon />} />
           </Route>

           <Route exact path="/dashboard/sp" element={<ServiceProviderLayout />} >
           <Route index element={<SpDashboardHome />} />
           <Route path="myservices" element={<MyServices />} /> 
           </Route>
         </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
