import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header'

function FrontLayout() {

    let isLoggedIn = false;
    const authToken = localStorage.getItem("loginToken");
    console.log("autoken is==="+authToken);
    if(authToken != null){
      isLoggedIn = true;
    }
  return (
    <>
      <Header isUserLoggedIn={isLoggedIn} />
     
      <Outlet />
      
      
      <Footer />
    </>
  )
}

export default FrontLayout
