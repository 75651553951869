import React from 'react'
import HotelProgressBar from './EntertainmentProgressBar'
import EntertainmentOrganizationDetailsForm from './EntertainmentOrganizationDetailsForm';
import EntertainmentOwnerDetailsForm from './EntertainmentOwnerDetailsForm';
import EntertainmentUploadsForm from './EntertainmentUploadsForm';
import EntertainmentSummaryForm from './EntertainmentSummaryForm';
import EntertainmentFeatureForm from './EntertainmentFeatureForm';
import { useDispatch, useSelector } from 'react-redux';
import { setFormDataAction, setStepAction } from '../../../../redux/action/multiStepFormAction';
import { AdsCategory, AdsStepType } from '../../../../constants';

function EntertainmentCommon() {
  const currentStep = useSelector(state => state.multiStepForm.step);
  const dispatch = useDispatch();

  function setFormFilledData(data, type) {
    dispatch(setFormDataAction({
      type: AdsCategory.entertainment,
      data: { [type]: data }
    }))
  }

  function setStep(step) {
    console.log("PASSED Step FROM CHILD IS ==" + step);
    dispatch(setStepAction(step));
  }

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return <EntertainmentOrganizationDetailsForm
          type={AdsStepType.organization}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />

      case 2:
        return <EntertainmentOwnerDetailsForm
          type={AdsStepType.owner}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />

      case 3:
        return <EntertainmentFeatureForm
          type={AdsStepType.features}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 4:
        return <EntertainmentUploadsForm
          type={AdsStepType.uploads}
          setStep={setStep}
          setFormFilledData={setFormFilledData} />
      case 5:
        return <EntertainmentSummaryForm
          setStep={setStep} />
      default:
        return null;
    }
  }
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div style={{ marginLeft: '15%' }}>
              <HotelProgressBar stepNo={currentStep} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {
              getForm()
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default EntertainmentCommon
