import React from 'react'


function LeftSideProgress({currentStep}) {

  const step = currentStep;
  console.log("Props", currentStep);
  return (
    <>
       <div className="left-heading">
                    <h4 className='pt-5'>Registger Your Vehicle</h4>
                </div>
                <div className="steps-content">
                    <h5>Step <span className="step-number">{step}</span></h5>
                    <p className={`step-number-content ${step==1 ? "active" : "d-none"}`}>Enter your personal information to get closer to companies.</p>
                    <p className={`step-number-content ${step==2 ? "active" : "d-none"}`}>Get to know better by adding your diploma,certificate and education life.</p>
                    <p className={`step-number-content ${step==3 ? "active" : "d-none"}`}>Help companies get to know you better by telling then about your past experiences.</p>
                    <p className={`step-number-content ${step==4 ? "active" : "d-none"}`}>Add your profile piccture and let companies find youy fast.</p>
                </div>
                <ul className="progress-bar">
                    <li className={`${step==1 ? "active" : ""}`}>Organization Information</li>
                    <li className={`${step==2 ? "active" : ""}`}>Vehicle Information</li>
                    <li className={`${step==3 ? "active" : ""}`}>Owner Information</li>
                    <li className={`${step==4 ? "active" : ""}`}>Driver Information</li>
                    <li className={`${step==5 ? "active" : ""}`}>Upload</li>
                    <li className={`${step==6 ? "active" : ""}`}>Summary</li>
                </ul>
                
    </>
  )
}

export default LeftSideProgress
