const SET_CATEGORY = "SET_CATEGORY";
const SET_STEP = "SET_STEP";
const SET_FORM = "SET_FORM";
const RESET_FORM = "RESET_FORM";

export {
    SET_CATEGORY,
    SET_STEP,
    SET_FORM,
    RESET_FORM
}