import React from 'react'

function DriverDetail({register, errors}) {
  return (
    <>
       <div className="main active">
       
                    <div className="d-flex">
                    <small><i className="bx bx-user"></i></small><h2 className='mx-3'>Driver Details</h2>
                    </div>
                    {/* <p>Provide Driver details for further communication</p> */}
                    <div className="input-text">
                        
                            <input type="checkbox" style={{width:"20px", height:"20px"}}/> <span>Check If Login User is Owner</span>
                            
                        </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name="driverFirstName" {...register("driverFirstName", { required: true })} />
                            <span>First Name</span>
                        </div>
                        <div className="input-div"> 
                            <input type="text" name="driverLastName" {...register("driverLastName", { required: true })} />
                            <span>Last Name</span>
                        </div>
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name="driverMobileNo" {...register("driverMobileNo", { required: true })} />
                            <span>Contact Number</span>
                        </div>
                        <div className="input-div">
                            <input type="text" name="driverEmailId" {...register("driverEmailId", { required: true })} />
                            <span>Email Id</span>
                        </div>
                        
                    </div>
                    <div className="input-text">
                    <div className="input-div">
                            <select name="driverGender" {...register("driverGender", { required: true })}>
                                <option>Select Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Trans Gender</option>
                                <option>Other</option>
                                
                            </select>
                        </div>
                        <div className="input-div">
                        <input type="date" name="driverDob" {...register("driverDob", { required: true })} />
                            <span>DOB</span>
                        </div>
                    </div>
                    <div className="input-text">
                    <div className="input-div">
                    <input type="text" name="driverLanguage" {...register("driverLanguage", { required: true })} />
                            <span>Known Language</span>
                            
                        </div>
                        {/* <div className="input-div">
                            
                             <input type="checkbox" name="driverLanguage" {...register("driverLanguage", { required: false })} value="English" /><label className="mx-1">Hindi</label>
                            <input type="checkbox" name="driverLanguage" {...register("driverLanguage", { required: false })} value="Hindi" /><label className="mx-1">English</label>
                            <input type="checkbox" name="driverLanguage" {...register("driverLanguage", { required: false })} value="Bhojpuri" /><label className="mx-1">Bhojpuri</label>
                            <input type="checkbox" name="driverLanguage" {...register("driverLanguage", { required: false })} value="Punjabi" /><label className="mx-1">Punjabi</label> 
                        </div> */}
                       
                    </div>
                  
                </div>
    </>
  )
}

export default DriverDetail
