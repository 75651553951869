import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';

function EditBrand() {

    //const currentData = props.data;
   
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [response, setResponse] = useState("");
    const [failedResponse, setFailedResponse] = useState("");
    const navigate = useNavigate();
    const {id}  = useParams();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
   
    const jwtToken = localStorage.getItem('loginToken');
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    const onSubmit = (requestedData) => { 
    console.log(requestedData);
      setResponse("");
      setFailedResponse("");
       axios.post(`${baseUrl}/api/v1/vehicle/brand/update/${requestedData.brandId, config}`, requestedData).then((res) =>{
        console.log("Response for fetchdata",res.data.msg);
        console.log("Response for fetchdata",res.data.status);
        if(res.data.status == 'OK'){
            setResponse(res.data.msg);
        }else{
            setFailedResponse(res.data.msg)
        }
        
        console.log(baseUrl,"BASE URL")
       }).catch(err =>{
         console.log("ERROR    ",err);
         console.log("BASE URL    ",baseUrl);
       });
  }

  const fetchBrand = (brandId) =>{
    axios.get(`${baseUrl}/api/v1/vehicle/brand/${brandId}`, config).then((res) =>{
        console.log("BRAND DATA IS",res.data.data);
        const brandRes = res.data.data;
        setValue("brandName", brandRes.brandName);
        setValue("brandLabel", brandRes.brandLabel);
        setValue("brandId", brandRes.brandId);
       }).catch(err =>{
         console.log("ERROR    ",err);  
       });
  }
  useEffect(()=>{
    const decodedBrandId = atob(id);
      fetchBrand(decodedBrandId);
      console.log("Brand id is"+id);
  },[])
  return (
    
    
    <div className="col-lg-8 col-md-8 mx-auto" style={{marginTop:"7%"}}>
                  <div className="card-body p-4 p-lg-5 text-black" style={{backgroundColor:"#fff"}}>
                  <p className="text-center">UPFDATE BRAND</p>
                  {response.length > 0 ? 
                  <div class="alert alert-success" role="alert">
                      {response}
                   </div>:""
                   }
                    {failedResponse.length > 0 ? 
                  <div class="alert alert-danger" role="alert">
                      {failedResponse}
                   </div>:""
                   }

                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Brand Name</label>
                        <input type="text" name="brandName" className="form-control form-control-md" {...register("brandName", { required: true })}/>
                        {errors.brandName && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Brand Label</label>
                        <input type="text" name="brandLabel" className="form-control form-control-md" {...register("brandLabel", { required: true })}/>
                        {errors.brandLabel && <span className="form-error-dk">This field is required</span>}
                        <input type="hidden" name="brandId" {...register("brandId", { required: true })} />
                      </div>
                      
                      
    
                      <div className="col-md-12 col-lg-12 d-flex ">
                      <div className="pt-1 mb-4">
                        <button className="btn btn-dark btn-lg btn-block" type="submit">Update</button>
                      </div>
                      <div className="pt-1 mb-4 mx-2">
                        <button className="btn btn-dark btn-lg btn-block" type="button" onClick={()=> navigate('/dashboard/brand-list') }> <i className="bx bx-chevron-left"></i>Back</button>
                      </div>
                      </div>
                     
                    </form>
    
                  </div>
                </div>
    
  )
}

export default EditBrand
