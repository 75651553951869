import React from 'react'

function ErrorModal({closeModal}) {
  return (
    <>
      <div className="modal1">
<div className="modal-content">
      <div className="modal-header">
        <h6 className="modal-title" id="staticBackdropLabel"></h6>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{closeModal(false)}}></button>
      </div>
     <div className='modal-body'>
       {/* {content} */}
     </div>
      
    </div>

</div>
    </>
  )
}

export default ErrorModal
