import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'

function AddModel() {

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [brands, setBrands] = useState([]);
    const [response, setResponse] = useState("");
    const [failedResponse, setFailedResponse] = useState("");
    const [selectedOption, setSelectedOption] = useState(0);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
   
    const jwtToken = localStorage.getItem('loginToken');
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    const onSubmit = (requestedData) => { 
        console.log(requestedData);
          setResponse("");
          setFailedResponse("");
        axios.post(baseUrl+"/api/v1/vehicle/model/add/"+selectedOption, requestedData, config).then((res) =>{
            console.log("Response for fetchdata",res.data.msg);
            console.log("Response for fetchdata",res.data.status);
            if(res.data.status == 'OK'){
                setResponse(res.data.msg);
            }else{
                setFailedResponse(res.data.msg)
            }
            
            console.log(baseUrl,"BASE URL")
           }).catch(err =>{
             console.log("ERROR    ",err);
             console.log("BASE URL    ",baseUrl);
           });
      }
   
 
  const fetchSidebarData = () =>{
    axios.get(baseUrl+"/api/v1/vehicle/brand/list", config).then((res) =>{
     console.log("Response",res.data.data);
     setBrands(res.data.data);
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
    
  }

  useEffect(()=>{
    fetchSidebarData();  
    console.log(selectedOption+"selectedOption")
  },[])

  function brandChanged(e){
      console.log(e.target.value);
      setSelectedOption(e.target.value);
  }
  return (
    <>
       <div className="col-lg-8 col-md-8 mx-auto" style={{marginTop:"7%"}}>
                  <div className="card-body p-4 p-lg-5 text-black" style={{backgroundColor:"#fff"}}>
                  <p className="text-center">ADD MODEL</p>
                  {response.length > 0 ? 
                  <div class="alert alert-success" role="alert">
                      {response}
                   </div>:""
                   }
                    {failedResponse.length > 0 ? 
                  <div class="alert alert-danger" role="alert">
                      {failedResponse}
                   </div>:""
                   }
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Select Brand</label>
                        {/* <input type="text" name="brandName" className="form-control form-control-md" {...register("brandName", { required: true })}/> */}
                        <select name="brand" className="form-control form-control-md" {...register("brand", { required: true })} onChange={brandChanged}>
                         <option value="">Select Brand</option>
                         {brands.map((res)=>{
                             return(
                                 <option value={res.brandId} key={res.brandId}>{res.brandName}</option>
                             )
                         })}
                        </select>
                        {errors.brand && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Model Name</label>
                        <input type="text" name="modelName" className="form-control form-control-md" {...register("modelName", { required: true })}/>
                        {errors.modelName && <span className="form-error-dk">This field is required</span>}
                      </div>
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Model Label</label>
                        <input type="text" name="modelLabel" className="form-control form-control-md" {...register("modelLabel", { required: true })}/>
                        {errors.modelLabel && <span className="form-error-dk">This field is required</span>}
                      </div>
                      <div className="form-outline mb-2 ">
                      <label className="form-label-dk" htmlFor="form2Example17">Launching Year</label>
                        <input type="number" name="launchYear" className="form-control form-control-md" {...register("launchYear", { required: true })}/>
                        <input type="hidden" name="isEnabled" value="true" {...register("isEnabled", { required: true })} />
                        {errors.launchYear && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
    
                      <div className="col-md-12 col-lg-12 d-flex ">
                      <div className="pt-1 mb-4">
                        <button className="btn btn-dark btn-lg btn-block" type="submit">Add</button>
                      </div>
                     
                      </div>
                     
                    </form>
    
                  </div>
                </div>
    </>
  )
}

export default AddModel
