import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useForm } from 'react-hook-form';

function VehicleDetail({register, errors}) {

    const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([0]);
  const [models, setModels] = useState([0]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const[vehicleNo,setVehicleNo]=useState('');

  const jwtToken = localStorage.getItem('loginToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` }
};

 
  const fetchSidebarData = () =>{
    axios.get(baseUrl+"/api/v1/vehicle/brand/list", config).then((res) =>{
     console.log("Response",res.data.data);
     setBrands(res.data.data);
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
  }

  const fetchModelByBrand = (brandId) =>{
    axios.get(`${baseUrl}/api/v1/vehicle/model/list/${brandId}`,config).then((res) =>{
      console.log("Response model",res.data.data);
     setModels(res.data.data);
     console.log(models);
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
    
  }

  function brandChanged(e){
     setSelectedBrand(e.target.value);
     console.log("SELECTED BRAND IS=== "+e.target.value)
     fetchModelByBrand(e.target.value);
}
const handleInput=(event)=>{
  event.preventDefault();
  setVehicleNo(event.target.value);   
}

  useEffect(() => {
    fetchSidebarData();
    fetchModelByBrand(0);
    console.log("use effect called base url is ==="+baseUrl);
  }, []);
  
  return (
    <>
      <div className="main active">
      <div className="d-flex">
                    <small><i className="bx bx-car"></i></small><h2 className='mx-3'>Vehicle Information</h2>
                    </div>
                   
                    <div className="input-text">
                        <div className="input-div">
                            {/* <input type="text" required require id="user_name" />
                            <span>First Name</span> */}

                            <select name="brand" {...register("brand", { required: true })} onChange={brandChanged} >
                                <option>Select Brand</option>
                                {
                         brands.map((item) => {
                           return(
                          <option key={item.brandId} value={item.brandId}>{item.brandName}</option>
                           )
                          
                         })
                        }
                            </select>
                              {errors.brand && <p className="form-error-dk">This field is required</p>}
                        </div>
                        
                            
                        <div className="input-div"> 
                        <select name="model" {...register("model", { required: true })}>
                                <option>Select Model</option>
                                {
                         models.map((item) => {
                           return(
                          <option key={item.modelId} value={item.modelId}>{item.modelName}</option>
                           )
                          
                         })
                        }
                            </select>
                            {errors.model && <p className="form-error-dk">This field is required</p>}
                        </div>
                    </div>
                    {/* <div className="input-text">
                        <div className="input-div">
                            <input type="text" required require />
                            <span>Phone number</span>
                        </div>
                        <div className="input-div">
                            <input type="text" required require />
                            <span>E-mail Address</span>
                        </div>
                    </div> */}
                    <div className="input-text">
                        <div className="input-div">
                            <select name="colour" {...register("colour", { required: true })}>
                                <option>Vehicle Colour</option>
                                <option>Red</option>
                                <option>White</option>
                                <option>Silver</option>
                                <option>Blue</option>
                                <option>Maroon</option>
                                <option>Green</option>
                                <option>Black</option>
                                
                            </select>
                            {errors.colour && <p className="form-error-dk">This field is required</p>}
                        </div>
                        <div className="input-div">
                            
                            <select name="launchYear" {...register("launchYear", { required: true })}>
                                <option>Launching Year</option>
                                <option>2010</option>
                                <option>2011</option>
                                <option>2012</option>
                                <option>2013</option>
                                <option>2014</option>
                                <option>2015</option>
                                <option>2016</option>
                                <option>2017</option>
                                <option>2018</option>
                                <option>2019</option>
                                <option>2020</option>
                                <option>2021</option>
                                <option>2022</option>
                            </select>
                            {errors.launchYear && <p className="form-error-dk">This field is required</p>}
                        </div>
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name='vehicleNo' {...register("vehicleNo", { required: true })} />
                            <span>Vehicle Number</span>
                            {errors.vehicleNo && <p className="form-error-dk">This field is required</p>}
                        </div>
                        
                        {/* <div className="input-div">
                            <input type="text" required require />
                            <span>E-mail Address</span>
                        </div> */}
                    </div>
                    {/* </form> */}
                </div>
    </>
  )
}

export default VehicleDetail
