import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header'
import SpDashboardHeader from '../service-provider/sp-dashboard/SpDashboardHeader';
import SpDashboardSidebar from '../service-provider/sp-dashboard/SpDashboardSidebar';

function ServiceProviderLayout() {

    let isLoggedIn = false;
    const authToken = localStorage.getItem("loginToken");
    console.log("autoken is==="+authToken);
    if(authToken != null){
      isLoggedIn = true;
    }
  return (
    <>
      <SpDashboardHeader isUserLoggedIn={isLoggedIn} />
     
      <div className='row'>
        <div className='col-lg-2 col-md-2'>
        <SpDashboardSidebar/>
        </div>
        <div className='col-lg-10 col-md-10 mx-auto' style={{marginTop:'6%'}}>
        <Outlet />
        </div>
        
      </div>
    </>
  )
}

export default ServiceProviderLayout
