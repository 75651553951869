import React, { useState, useEffect } from 'react'
import {paginate} from './Utils'

function Pagination({initialPage, pageSize, totalData}) {

    let numberOfPages = Math.ceil(totalData/pageSize);

    let [currentPage, setCurrentPage] = useState(initialPage);

    const previousPage = (() =>{
         currentPage--;
         setCurrentPage(currentPage);
         paginate(currentPage);
        console.log("Previus clicked current page is "+currentPage);
    })

    const nextPage = (() =>{
         currentPage++;
         setCurrentPage(currentPage);
         paginate(currentPage);
        console.log("Next clicked current page is "+currentPage);
    })

    const clickedOnPage = (() =>{
          console.log("clicked current page is "+currentPage);
    })

    // const paginate = ((page) =>{
    //     console.log("Paginate number is "+page+" and page size is "+pageSize);
    // })
    useEffect(() => {
    
        
        setCurrentPage(initialPage);
        console.log("Current Page is "+currentPage);
    
      //console.log(baseUrl,"BASE URL")
      }, []);

  return (

     
    <div>
 {
   totalData > 0 ?  <>
   <ul class="pagination pagination-lg">
    {currentPage > 1 ? <li class="page-item"><a class="page-link" href="javascript:void(0)" onClick={previousPage}>Previous</a></li>:<li class="page-item disabled"><a class="page-link" href="javascript:void(0)">Previous</a></li>}
  <li class="page-item"><a class="page-link" href="javascript:void(0)" onClick={clickedOnPage}>{currentPage}</a></li>
  {currentPage < numberOfPages ? <li class="page-item"><a class="page-link" href="javascript:void(0)" onClick={nextPage}>Next</a></li>:<li class="page-item disabled"><a class="page-link" href="javascript:void(0)">Next</a></li>}
</ul>
   </>:<></>
 }
 
    </div>
  )
}

export default Pagination
