import React, { useEffect } from 'react'
import TransportCommon from './transport/TransportCommon'
import HotelCommon from './hotels/HotelCommon'
import EntertainmentCommon from './entertainment/EntertainmentCommon';
import MakeupCommon from './makeup/MakeupCommon';
import CateringCommon from './catering/CateringCommon';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryIds, getQueryParams } from '../../../constants';
import { setCategoryAction } from '../../../redux/action/multiStepFormAction';

function AdPost() {
  const dispatch = useDispatch();
  const category = useSelector(state => state.multiStepForm.category);

  useEffect(() => {
    const categoryId = getQueryParams("c_id");
    const subCategoryId = getQueryParams("sc_id");
    if (!category && categoryId && subCategoryId) {
      dispatch(setCategoryAction({
        category: +categoryId,
        subCategory: +subCategoryId,
      }))
    }
  }, [category, dispatch])

  switch (category) {
    case CategoryIds.transport:
      return <TransportCommon />;
    case CategoryIds.makeupAndGrooming:
      return <MakeupCommon />
    case CategoryIds.entertainment:
      return <EntertainmentCommon />
    case CategoryIds.hotel:
      return <HotelCommon />
    case CategoryIds.catering:
      return <CateringCommon />
    default:
      return null;
  }
}

export default AdPost
