import React from 'react'
import { Link } from 'react-router-dom'
import { BiAlarmAdd } from "react-icons/bi";
import './Dashboard.css'

export default function DashboardSidebar() {
  return (
    <>
      
  <div className="side-navbar d-flex justify-content-between flex-wrap flex-column" id="sidebar">
    <ul className="nav flex-column text-white w-100">
      <a href="#" className="nav-link h3 text-white my-4">
        
      </a>
      <li href="#" className="nav-link">
      <Link className="nav-link-inner" to="/dashboard">
        <i className="bx bxs-dashboard"></i>
        <span className="mx-2"> Home</span>
        </Link>
      </li>
      <li href="#" className="nav-link" >
        <a className="nav-link-inner dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <i className="bx bx-user"></i>
        <span className="mx-2">Admin</span>

          </a>
          <ul class="dropdown-menu dropdown-menu-dark w-100" aria-labelledby="navbarDarkDropdownMenuLink">
            <li><Link className="dropdown-item" to="/dashboard/admin/category/add">Add Category</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/admin/sub-category/add">Add Sub Category</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/admin/category/list">Category List</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/admin/sub-category/list">Sub Category List</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/add-model">Add Model</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/brand-list">Brand List</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/model-list">Model List</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/vehicle/add">Add Vehicle</Link></li>
        
          </ul>
      </li>

      <li href="#" className="nav-link" >
        <a className="nav-link-inner dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <i className="bx bx-car"></i>
        <span className="mx-2">Vehicles</span>

          </a>
          <ul class="dropdown-menu dropdown-menu-dark w-100" aria-labelledby="navbarDarkDropdownMenuLink">
            <li><Link className="dropdown-item" to="/dashboard/add-brand">Add Brand</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/add-model">Add Model</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/brand-list">Brand List</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/model-list">Model List</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/vehicle/add">Add Vehicle</Link></li>
        
          </ul>
      </li>

      <li href="#" className="nav-link" >
        <a className="nav-link-inner dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <i className="bx bxs-dashboard"></i>
        <span className="mx-2">Services</span>

          </a>
          <ul class="dropdown-menu dropdown-menu-dark w-100" aria-labelledby="navbarDarkDropdownMenuLink">
            <li><Link className="dropdown-item" to="/dashboard/transport-list">Transports</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/add-model">Beauty Parlour</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/brand-list">Hotel</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/model-list">Band Baja</Link></li>
            <li><Link className="dropdown-item" to="/dashboard/vehicle/add">Tent House</Link></li>
        
          </ul>
      </li>
      <li href="#" className="nav-link">
        <i className="bx bx-conversation"></i>
        <span className="mx-2">Contact</span>
      </li>
    </ul>

    <span href="#" className="nav-link h4 w-100 mb-5">
      <a href=""><i className="bx bxl-instagram-alt text-white"></i></a>
      <a href=""><i className="bx bxl-twitter px-2 text-white"></i></a>
      <a href=""><i className="bx bxl-facebook text-white"></i></a>
    </span>
  </div>
    </>
  )
}
