import { SET_ENTERTAINMENT_LIST, SET_MAKEUP_LIST} from "../constant"



export const entertainmentData = (data = [], action) => {
    switch (action.type) {
            case SET_ENTERTAINMENT_LIST:
                console.warn("ENTERTAINMENT_LIST condition ", action)
                return [...action.data]
        default:
            return data
    }
}