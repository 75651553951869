import { SET_MAKEUP_LIST} from "../constant"



export const makeupData = (data = [], action) => {
    switch (action.type) {
            case SET_MAKEUP_LIST:
                console.warn("MAKEUP_LIST condition ", action)
                return [...action.data]
        default:
            return data
    }
}