import React from 'react'

function OwnerDetail({register, errors}) {
  return (
    <>
       <div className="main active">
       <div className="d-flex">
                    <small><i className="bx bx-user"></i></small><h2 className='mx-3'>Owner Details</h2>
                    </div>
                    <div className="input-text">
                        
                            <input type="checkbox" style={{width:"20px", height:"20px"}}/> <span>Check If Login User is Owner</span>
                            
                        </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name="ownerFirstName" {...register("ownerFirstName", { required: true })} />
                            <span>First Name</span>
                        </div>
                        <div className="input-div"> 
                            <input type="text" name="ownerLastName" {...register("ownerLastName", { required: true })} />
                            <span>Last Name</span>
                        </div>
                    </div>
                    <div className="input-text">
                        <div className="input-div">
                            <input type="text" name="ownerMobileNo" {...register("ownerMobileNo", { required: true })}/>
                            <span>Mobile Number</span>
                        </div>
                        <div className="input-div">
                            <input type="text" name="ownerEmailId" {...register("ownerEmailId", { required: true })} />
                            <span>Email Id</span>
                        </div>
                        
                    </div>
                    <div className="input-text">
                    <div className="input-div">
                            <select name="ownerGender" {...register("ownerGender", { required: true })}>
                                <option>Select Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Trans Gender</option>
                                <option>Other</option>
                                
                            </select>
                        </div>
                        <div className="input-div">
                        <input type="date" name="ownerDob" {...register("ownerDob", { required: true })} />
                            <span>DOB</span>
                        </div>
                    </div>
                  
                </div>
    </>
  )
}

export default OwnerDetail
