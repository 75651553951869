import React from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

function MakeupOwnerDetailsForm({
  type,
  setStep,
  setFormFilledData,
}) {
  const ownerState = useSelector(state => state.multiStepForm.makeupAndGrooming.owner);
  const { register, handleSubmit, formState: { errors } } = useForm(
    {
      defaultValues: ownerState
    }
  );


  const backStep = () => {
    setStep(1);
  }
  const onSubmit = (data) => {
    setFormFilledData(data, type);
    setStep(3);
  };

  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>


          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Owner First Name *</label>
                <input type="text" className="form-control" name="ownerFirstName" {...register("ownerFirstName", { required: true })} />
                {errors.ownerFirstName && <span className="form-error-dk">This field is required</span>}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Owner Last Name *</label>
                <input type="text" className="form-control" name="ownerLastName" {...register("ownerLastName", { required: true })} />
                {errors.ownerLastName && <span className="form-error-dk">This field is required</span>}
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Mobile No *</label>
                <input type="text" className="form-control" name="ownerMobileNo" {...register("ownerMobileNo", { required: true })} />
                {errors.ownerMobileNo && <span className="form-error-dk">This field is required</span>}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Email Id *</label>
                <input type="text" className="form-control" name="ownerEmailId" {...register("ownerEmailId", { required: true })} />
                {errors.ownerEmailId && <span className="form-error-dk">This field is required</span>}
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Gender *</label>
                <select className="form-select" name='ownerGender' {...register("ownerGender", { required: true })}>
                  <option value=''>Select Gender</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='TransGender'>TransGender</option>
                </select>
                {errors.ownerGender && <span className="form-error-dk">This field is required</span>}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">DOB *</label>
                <input type="date" className="form-control" name="ownerDob" {...register("ownerDob", { required: true })} />
                {errors.ownerDob && <span className="form-error-dk">This field is required</span>}
              </div>

            </div>

            <div className='row mt-5'>
              <div className='col'>
                <>
                  <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                  <button className="btn btn-warning" type="submit">Next Step</button>
                </>

              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default MakeupOwnerDetailsForm
