import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Loader from '../../../../utils/Loader';
import ThankYouModal from '../../../../utils/ThankYouModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fileToBlob } from '../../../../utils/helpers';
import { resetMultiFormAction } from '../../../../redux/action/multiStepFormAction';

function EntertainmentSummaryForm({
  setStep,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    launchYear: "",
    vehicleColour: "",
    vehicleNo: "",
    organization: {},
    owner: {},
    driver: {},
    features: [],
    uploads: [],
    isFormLoading: true,
  });
  const entertainmentData = useSelector(state => state.multiStepForm.entertainment);
  const category = useSelector(state => state.multiStepForm.category);
  const subCategory = useSelector(state => state.multiStepForm.subCategory);
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [openModal, setModal] = useState(false);
  const [failedResponse, setFailedResponse] = useState("");
  const hasFormData = useCallback((data) => {
    if (Object.values(data.organization).length > 0 && Object.values(data.owner).length > 0) {
      return true;
    }
    return false;
  }, [])
  useEffect(() => {
    if (hasFormData(entertainmentData)) {
      const data = {
        organization: entertainmentData.organization,
        owner: entertainmentData.owner,
        features: entertainmentData.features.features.map((i => ({ feature: i.name }))),
        uploads: entertainmentData.uploads,
        category: category,
        subCategory: subCategory,
        isFormLoading: false,
      }

      setFormData(data);
    }
  }, [category, hasFormData, subCategory, entertainmentData]);
  const {
    organization,
    owner,
    features,
    uploads: {
      featuredImage,
      gallery
    },
    isFormLoading } = formData;

  const backStep = () => {
    setStep(4);
  }
  const onSubmit = (data) => {

    const _formData = new FormData();
    const { isFormLoading, uploads, ...reqFormData } = formData;
    reqFormData.uploads = {
      featuredImage: uploads.featuredImage.name,
      gallery: [uploads.gallery.name]
    };
    _formData.append('data', JSON.stringify(reqFormData));
    _formData.append('featuredImage', formData.uploads.featuredImage);
    _formData.append('gallery', formData.uploads.gallery)
    setLoading(true);
    setFailedResponse("");


    // console.log("FORM DATA REQUEST ===", formData)

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userToken = localStorage.getItem('loginToken');
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${userToken}`
      }
    };
    axios.post(`${baseUrl}/api/v1/entertainment/register`, _formData, axiosConfig).then((res) => {
      // console.log("Response", res.data.data);

      if (res.data.status === 'OK') {
        setLoading(false);
        setModal(true);
      }
      else if (res.data.status === 'BAD_REQUEST') {
        setLoading(false);
        // console.log("SOMETHING WENT WRONG ==", res.data.msg);
        setFailedResponse(res.data.msg);
      }

    }).catch(err => {
      setLoading(false);
      // console.log("ERROR    ", err);
      setFailedResponse(err);
    });
  };

  function handleModalClose() {
    navigate("/")
    dispatch(resetMultiFormAction());
  }

  return (
    <div>
      {loading ? <Loader /> : ''}
      {
        !isFormLoading &&
        <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
          <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff" }}>


            <form noValidate onSubmit={handleSubmit(onSubmit)}  >
              <h3 style={{ color: '#ff9800', fontSize: '13px' }}>Orgnization Details</h3>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%' }}>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Company Name *</label>
                    <input type="text" className="form-control" name="orgName" value={organization.orgName} readOnly />


                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">GST No</label>
                    <input type="text" className="form-control" name="gstNo" value={organization.gstNo} readOnly />
                  </div>

                </div>

                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">TIN No</label>
                    <input type="text" className="form-control" name="tinNo" value={organization.tinNo} readOnly />
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Registration No</label>
                    <input type="text" className="form-control" name="regNo" value={organization.regNo} readOnly />
                  </div>

                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">State *</label>
                    <select className="form-select" name='state' readOnly>

                      <option value={organization.state}>{organization.state}</option>


                    </select>

                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">District *</label>
                    <select className="form-select" name='district' readOnly >
                      <option value={organization.district}>{organization.district}</option>

                    </select>

                  </div>

                </div>

                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Area *</label>
                    <select className="form-select" name='area' readOnly >

                      <option value={organization.area}>{organization.area}</option>

                    </select>

                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Pin Code *</label>

                    <input type="text" className="form-control" name="pincode" value={organization.pincode} readOnly />


                  </div>


                </div>

                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">City *</label>
                    <input type="text" className="form-control" name="city" value={organization.city} readOnly />

                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Contact No</label>
                    <input type="text" className="form-control" name="contactNo" value={organization.contactNo} readOnly />

                  </div>

                </div>
                <div className='row'>
                  <div className="col">
                    <label className="form-label-dk">Office Address *</label>
                    <textarea className="form-control" name="address" value={organization.address} readOnly />

                  </div>
                </div>
              </section>



              <h3 style={{ color: '#ff9800', fontSize: '13px', paddingTop: '1%' }}>Owner Details</h3>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%' }}>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Owner First Name *</label>
                    <input type="text" className="form-control" name="ownerFirstName" value={owner.ownerFirstName} readOnly />

                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Owner Last Name *</label>
                    <input type="text" className="form-control" name="ownerLastName" value={owner.ownerLastName} readOnly />

                  </div>

                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Mobile No *</label>
                    <input type="text" className="form-control" name="ownerMobileNo" value={owner.ownerMobileNo} readOnly />

                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Email Id *</label>
                    <input type="text" className="form-control" name="ownerEmailId" value={owner.ownerEmailId} readOnly />

                  </div>

                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">Gender *</label>
                    <select className="form-select" name='ownerGender' value={owner.ownerGender} readOnly>
                      <option value={owner.ownerGender}>{owner.ownerGender}</option>

                    </select>

                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="form-label-dk">DOB *</label>
                    <input type="date" className="form-control" name="ownerDob" value={owner.ownerDob} readOnly />

                  </div>

                </div>
              </section>


              <h3 style={{ color: '#ff9800', fontSize: '13px', paddingTop: '1%' }}>Features</h3>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%' }}>

                <div className="row">
                  {
                    features?.map((item, index) => {
                      return (
                        <div className="col-lg-12 col-sm-12">
                          <label className="form-label-dk">Feature {index + 1}</label>
                          <div className='d-flex'>
                            <input type="text" className="form-control width-90" name="feature" value={item.feature} readOnly />
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </section>

              <h3 style={{ color: '#ff9800', fontSize: '13px', paddingTop: '1%' }}>Uploads</h3>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%' }}>
                <label className="form-label-dk"> Feature Image</label>
                <div className="row">
                  <div className="col-lg-12 col-sm-12">

                    {/* <input type="file" className="form-control" name="featuredImage" /> */}
                    {featuredImage && <img src={fileToBlob(featuredImage)} style={{ width: '150px', height: '200px' }} alt="feature" />}
                  </div>


                </div>
              </section>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%', marginTop: '0%' }}>
                <label className="form-label-dk"> Gallery Image</label>
                <div className="row">

                  <div className="col-lg-12 col-sm-12">

                    {/* <input type="file" className="form-control" name="gallery"  /> */}
                    {gallery && <img src={gallery} style={{ width: '150px', height: '200px' }} alt="gallery" />}
                  </div>
                </div>


              </section>


              <div className='row mt-5'>
                <div className='col'>
                  {failedResponse.length > 0 ?
                    <div class="alert alert-danger" role="alert">
                      {failedResponse}
                    </div> : ""
                  }
                  <>
                    <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                    <button className="btn btn-warning" type="submit">Submit</button>
                  </>

                </div>
              </div>

            </form>

          </div>
        </div>
      }
      {openModal && <ThankYouModal closeModal={setModal} onClose={handleModalClose} />}
    </div>
  )
}

export default EntertainmentSummaryForm
