import { combineReducers } from 'redux'
import { entertainmentData } from './entertainmentReducer'
import { makeupData } from './makeupReducer'
import { multiStepForm } from './multiStepForm'
import { transportData } from './transportReducer'

export default combineReducers({
   transportData: transportData,
   makeupData: makeupData,
   entertainmentData: entertainmentData,
   multiStepForm: multiStepForm,
})