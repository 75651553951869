import React from 'react'
import './SearchBar.css';

export default function SearchBar() {
  return (
    <div>
       <div className="header-search mt-3">
                    <div className="search-form">
                        <form method="get">
                            <div className="input-group">
                                <div className="location-selection">
                                    <select name="order" className="form-control">
                                        <option className="topshow" value="">Select City</option>
                                        <option value="a">Delhi</option>
                                        <option value="b">Gurugram</option>
                                        <option value="c">Noida</option>
                                        <option value="d">Lucknow</option>
                                        <option value="e">Patna</option>
                                        <option value="f">Bettiah</option>
                                    </select>
                                </div>
                                <input type="search" name="search" id="search" className="form-control" placeholder="Search Here" />
                                <div className="input-group-addon">
                                    <button type="submit"><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    </div>
  )
}
