import { put, all, } from 'redux-saga/effects'
import { RESET_FORM, SET_CATEGORY, SET_FORM, SET_STEP } from '../action-types/multiStepFormTypes';

function* setCategory(data) {
    yield put({ type: SET_CATEGORY, data })
}

function* setStep(data) {
    yield put({ type: SET_STEP, data })
}

function* setFormData(data) {
    yield put({ type: SET_FORM, data })
}

function* resetFormData() {
    yield put({ type: RESET_FORM })
}

export default all([
    setCategory,
    setStep,
    setFormData,
    resetFormData
]);