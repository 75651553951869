import React from 'react'
import './css/Footer.css'

function Footer() {
    return (
        <div>
            <footer>
                <div className='container'>
                    <div className='row' style={{padding:'2%'}}>
                    <div className='col-lg-3 col-md-3 col-sm-12'>
                    <h3>Popular Services</h3>
                    <ul className='footer'>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/kolkata_g4157275">Bridal Makeup</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/mumbai_g4058997">Grooming</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/chennai_g4059162">Transportation</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/pune_g4059014">Band Baja</a></li></ul>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <h3>Popular Location</h3>
                    <ul>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/kolkata_g4157275">Delhi/NCR</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/mumbai_g4058997">Patna</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/chennai_g4059162">Chandigarh</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/pune_g4059014">Lucknow</a></li></ul>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <h3>Trending Services</h3>
                    <ul>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/kolkata_g4157275">Kolkata</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/mumbai_g4058997">Mumbai</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/chennai_g4059162">Chennai</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/pune_g4059014">Pune</a></li></ul>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <h3>About SM</h3>
                    <ul>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/kolkata_g4157275">Kolkata</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/mumbai_g4058997">Mumbai</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/chennai_g4059162">Chennai</a></li>
                        <li><a class="_2XmAi" rel="" data-aut-id="" href="/pune_g4059014">Pune</a></li></ul>
                </div>
                    </div>
                </div>
               
               
               
            </footer>
            <div className='footer-lower'>
                <section className='left'>
                    <span>Follow Us</span>
                    <a href="https://www.facebook.com/" target='_blank'><i style={{fontSize:'1.5rem'}} class='bx bxl-facebook-circle'></i></a>
                    <a href="https://www.instagram.com/" target='_blank'><i style={{fontSize:'1.5rem'}} class='bx bxl-instagram-alt' ></i></a>
                    <a href="https://twitter.com/" target='_blank'><i style={{fontSize:'1.5rem'}} class='bx bxl-twitter'></i></a>
                </section>
                <section className='right'>
                    <span>All rights reserved © 2020-2023 SM</span>
                </section>
            </div>
        </div>
    )
}

export default Footer
