import React from 'react'
import { Link } from "react-router-dom";
import './Menu.css';

function MainMenu() {
    return (
        <div>
            <nav class="navbar navbar-expand-lg navbar-light bg-light" style={{ backgroundColor: '#ebeeef !important' }}>
                <div class="container-fluid">
                    <a class="navbar-brand" href="#"></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown1">
                        <ul className="navbar-nav mx-auto">
                            {/* <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link >
                                </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="category/catering-services">Catering Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="category/makeup-and-grooming">Makeup & Grooming</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="category/entertainment">Entertainment</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="transportDropDown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Transport
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="transportDropDown">
                                    <li><Link to="category/transports" className="dropdown-item">Vehicles</Link></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    {/* <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                </ul>
                                
                            </li>
                            <li className="nav-item">
                                <Link to="category/hotels" className="nav-link">Hotel</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default MainMenu
