import { RESET_FORM, SET_CATEGORY, SET_FORM, SET_STEP } from "../action-types/multiStepFormTypes"

const setCategoryAction = (data) => {
    return {
        type: SET_CATEGORY,
        payload: data
    }
}

const setStepAction = (data) => {
    return {
        type: SET_STEP,
        payload: data,
    }
}

const setFormDataAction = (data) => {
    return {
        type: SET_FORM,
        payload: data
    }
}

const resetMultiFormAction = () => {
    return {
        type: RESET_FORM
    }
}
export {
    setCategoryAction,
    setStepAction,
    setFormDataAction,
    resetMultiFormAction
}