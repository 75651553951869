import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

function CateringOrgDetailsForm({
  type,
  setStep,
  setFormFilledData,
}) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userToken = localStorage.getItem('loginToken');
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${userToken}`
    }
  };

  const orgState = useSelector(state => state.multiStepForm.catering.organization);


  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      orgName: '',
      gstNo: '',
      tinNo: '',
      regNo: '',
      state: '',
      district: '',
      area: '',
      pincode: '',
      city: '',
      contactNo: '',
      address: ''
    }
  });

  const [stateNameList, setStateNameList] = useState([]);
  const [districtNameList, setDistrictNameList] = useState([]);
  const [areaNameList, setAreaNameList] = useState([]);

  const [locationData, setLocationData] = useState({
    stateName: "",
    districtName: "",
    areaName: "",
    pincode: "",
  })

  const [failedResponse, setFailedResponse] = useState("");

  console.log("STTATE", locationData)

  const getStateList = () => {
    axios.get(baseUrl + "/api/v1/common/get/detail/by/state/master", axiosConfig).then((res) => {

      if (res.data.status === 'OK') {
        console.log("Response for fetchdata", res.data);
        console.log("Response for fetchdata", res.data.status);
        setStateNameList(res.data.data);
      }

      // console.log(baseUrl,"BASE URL")
    }).catch(err => {
      console.log('Exception ===' + err);
    });
  }

  const getDistrictList = (stateName) => {
    return axios.get(`${baseUrl}/api/v1/common/get/detail/by/district/master?stateName=${stateName}`, axiosConfig).then((res) => {
      if (res.data.status === 'OK') {
        setDistrictNameList(res.data.data);
      }
      // console.log(baseUrl,"BASE URL")
    }).catch(err => {
      console.log('Exception ===' + err);
    });
  }

  const getAreaList = (stateName, districtName) => {
    return axios.get(`${baseUrl}/api/v1/common/get/detail/by/area/master?stateName=${stateName}&districtName=${districtName}`, axiosConfig).then((res) => {

      if (res.data.status === 'OK') {
        console.log("Response for fetchdata", res.data);
        console.log("Response for fetchdata", res.data.status);
        setAreaNameList(res.data.data);
      }

      // console.log(baseUrl,"BASE URL")
    }).catch(err => {
      console.log('Exception ===' + err);
    });
  }

  function init() {
    if (Object.values(orgState).length > 0) {
      reset(orgState)
      setLocationData({
        stateName: orgState.state,
        areaName: orgState.area,
        districtName: orgState.district,
        pincode: orgState.pincode
      })
      if (orgState.state) {
        getDistrictList(orgState.state);
      }
      if (orgState.state && orgState.district) {
        getAreaList(orgState.state, orgState.district)
      }
    }
  }
  useEffect(() => {
    getStateList();
    init();
  }, []);

  useEffect(() => {
    init();
  }, [orgState, reset]);

  const stateChanged = (e) => {
    console.log('Selected State is ', e.target.value);

    setDistrictNameList([]);
    setAreaNameList([]);
    setLocationData({
      ...locationData,
      stateName: e.target.value
    })
    if (e.target.value !== '') {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/district/master?stateName=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setDistrictNameList(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setDistrictNameList([]);
      setAreaNameList([]);
    }

  }

  const districtChanged = (e) => {
    console.log("District is is ==" + e.target.value);
    console.log("State is is is ==" + stateNameList);
    setLocationData({
      ...locationData,
      districtName: e.target.value
    })
    setAreaNameList([]);
    if (e.target.value !== '' && stateNameList?.length > 0) {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/area/master?stateName=${locationData.stateName}&districtName=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setAreaNameList(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setAreaNameList([]);
    }
  }


  const areaChanged = (e) => {
    console.log("District is is ==" + e.target.value);
    console.log("State is is is ==" + stateNameList);

    setLocationData({
      ...locationData,
      areaName: e.target.value
    })
    // setAreaName([]);
    if (e.target.value !== '' && stateNameList !== '' && locationData.districtName !== '') {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/pincode/master?stateName=${locationData.stateName}&districtName=${locationData.districtName}&area=${e.target.value}`, axiosConfig)
        .then((res) => {

          if (res.data.status === 'OK') {
            console.log("Response for fetchdata", res.data);
            console.log("Response for fetchdata", res.data.status);
            setLocationData({
              ...locationData,
              areaName: e.target.value,
              pincode: res.data.data.pincode
            })
          } else {
            setFailedResponse(res.data.msg)
          }

          // console.log(baseUrl,"BASE URL")
        }).catch(err => {
          console.log('Exception ===' + err);
        });
    }
    else {
      setAreaNameList([]);
    }
  }

  const onSubmit = (data) => {
    setFormFilledData(data, type);
    setStep(2);
  };

  return (

    <div>
      {/* <>
    {orgObject.orgName && <HotelCommon orgObject={orgObject} step={currentStep} />}
    </> */}
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>


          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Organization/ Shop Name *</label>
                <input type="text" className={`form-control ${errors.orgName ? 'is-invalid' : ''}`} name="orgName" {...register("orgName", { required: true })} />
                {/* {errors.orgName && <span className="form-error-dk">This field is required</span>} */}

              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">GST No</label>
                <input type="text" className="form-control" name="gstNo" {...register("gstNo", { required: true })} />
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">TIN No</label>
                <input type="text" className="form-control" name="tinNo" {...register("tinNo", { required: true })} />
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Registration No</label>
                <input type="text" className="form-control" name="regNo" {...register("regNo", { required: true })} />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">State *</label>
                <select className={`form-select ${errors.state ? 'is-invalid' : ''}`} value={locationData.stateName} name='state' {...register("state", { required: true })} onChange={stateChanged} >

                  <option value=''>Select State</option>
                  {
                    stateNameList.length > 0 ? stateNameList.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }

                </select>
                {/* {errors.state &&<span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">District *</label>
                <select className={`form-select ${errors.district ? 'is-invalid' : ''}`} value={locationData.districtName} name='district' {...register("district", { required: true })} onChange={districtChanged} >

                  <option value=''>Select District</option>
                  {
                    districtNameList.length > 0 ? districtNameList.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }
                </select>

              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Area *</label>
                <select className={`form-select ${errors.area ? 'is-invalid' : ''}`} value={locationData.areaName} name='area' {...register("area", { required: true })} onChange={areaChanged} >

                  <option value=''>Select Area</option>
                  {
                    areaNameList.length > 0 ? areaNameList.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }
                </select>

              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Pin Code *</label>


                <input type="text" className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                  value={locationData.pincode}
                  name="pincode" {...register("pincode", { required: true })} readOnly={locationData.pincode !== ''} />


                {/* {errors.pincode && <span className="form-error-dk">This field is required</span>} */}
              </div>


            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">City *</label>
                <input type="text" className={`form-control ${errors.city ? 'is-invalid' : ''}`} name="city" {...register("city", { required: true })} />

              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Contact No</label>
                <input type="text" className={`form-control ${errors.contactNo ? 'is-invalid' : ''}`} name="contactNo" {...register("contactNo", { required: true })} />

              </div>

            </div>
            <div className='row'>
              <div className="col">
                <label className="form-label-dk">Office Address *</label>
                <textarea className={`form-control ${errors.address ? 'is-invalid' : ''}`} name="address" {...register("address", { required: true })} />
                {/* {errors.address && <span className="form-error-dk">This field is required</span>} */}
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>

                <>
                  <button className="btn btn-warning" type="submit">Next Step</button>
                  {/* <button className="next_button" type="submit">Submit</button> */}
                </>


              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default CateringOrgDetailsForm
