import React, { useEffect, useState } from 'react'
import axios from 'axios';


export default function Sidebar() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([""]);
  const [selectedBrand, setSelectedBrand] = useState("");
  
 
  const fetchSidebarData = () =>{
    axios.get(baseUrl+"/api/v1/transport/brands").then((res) =>{
     console.log("Response",res.data.data);
     setBrands(res.data.data);
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
    
  }

  const fetchModelByBrand = (brandId) =>{
    if(brandId != ""){
    axios.get(`${baseUrl}/api/v1/transport/models?brandId=${brandId}`).then((res) =>{
      console.log("Response model",res.data.data);
      if(res.data.data != null){
        setModels(res.data.data);
        
      }
     
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
  }
  console.log("MODELS LENGTH IS "+models.length);
  }

  function brandChanged(e){
     setSelectedBrand(e.target.value);
     //console.log("SELECTED BRAND IS=== "+e.target.value)
     fetchModelByBrand(e.target.value);
}

  useEffect(() => {
    fetchSidebarData();
    fetchModelByBrand(selectedBrand)

    //fetchModelByBrand(0);
    console.log("use effect called base url is ==="+baseUrl);
  }, []);
  

  return (
    
   <div className='col-lg-12 col-md-12 mt-2 mb-2' style={{backgroundColor:"#fff", borderRadius:"10px", minHeight:"100vh", position:"sticky", top:0}}>
   
   <ul className="list-group">
  <li className="list-group-item list-group-item-dk list-group-item-warning">
    Filter By Brand
  </li>
  </ul>
  <div className="form-outline mb-3 mx-1">
   <select name="brand" className="form-select mt-2 mx-auto" onChange={brandChanged}>
                        <option value="">Select Brand</option>
                        {
                         brands.map((item) => {
                           return(
                          <option key={item.brandId} value={item.brandId}>{item.brandLabel}</option>
                           )
                          
                         })
                        }
                    </select> 
                    
  </div>
  
   <ul className="list-group">
    {selectedBrand !== '' ?
    <>
    <li className="list-group-item list-group-item-dk list-group-item-warning">
    Check Model
  </li>
    </>:""
    }
  
  {models.length > 1 ? models.map((item, index)=>{
return(
<li key={index} className="list-group-item list-group-item-dk">
      <label className='w-100'>
      <input className="form-check-input me-1" type="checkbox" value={item.modelId} aria-label="..." />
       <span style={{color:"#333"}}>{item.modelName}</span>
      </label>
    </li>
)
  }):""}


 
</ul>


   </div>
   
  
  )
}
