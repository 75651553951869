import React from 'react'
import bannerImg from '../images/home-banner.webp'
import '../components/css/Home.css'
import Testimonial from './home-components/Testimonial';
import Service from './home-components/Service';
import HomePageSlider from '../utils/HomePageSlider';
import { Link } from 'react-router-dom';
import StarRating from '../utils/StarRating';
import TrendingTransport from './home-components/TrendingTransport';
import TrendingHotel from './home-components/TrendingHotel';
export default function Home() {

  const myStyle = {
    backgroundImage: `url(${bannerImg})`,
    height: '95vh',
    marginTop: '-40px',
    fontSize: '50px',
    backgroundSize: 'cover',
    // filter: `blur(1px)`,
    // backgroundRepeat: 'repeat',
  };
  return (
    <>
      {/* <div style={myStyle}>
        <div style={{ backgroundColor: '#000', width: '100%', height: '95vh', opacity: '0.7' }}>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div class="content">
              <h1 style={{ textAlign: 'center', fontSize: '3rem', padding: '2%' }}>Find Shadi service provider near you</h1>
              <p className='contentp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquam erat in ante malesuada, facilisis semper nulla semper. Phasellus sapien neque, faucibus in malesuada quis, lacinia et libero. Sed sed turpis tellus. Etiam ac aliquam tortor, eleifend
                rhoncus metus. Ut turpis massa, sollicitudin sit amet molestie a, posuere sit amet nisl. Mauris tincidunt cursus posuere. Nam commodo libero quis lacus sodales, nec feugiat ante posuere. Donec pulvinar auctor commodo. Donec egestas diam ut mi adipiscing,
                quis lacinia mauris condimentum. Quisque quis odio venenatis, venenatis nisi a, vehicula ipsum. Etiam at nisl eu felis vulputate porta.</p>
              <p className='contentp'>Fusce ut placerat eros. Aliquam consequat in augue sed convallis. Donec orci urna, tincidunt vel dui at, elementum semper dolor. Donec tincidunt risus sed magna dictum, quis luctus metus volutpat. Donec accumsan et nunc vulputate accumsan. Vestibulum
                tempor, erat in mattis fringilla, elit urna ornare nunc, vel pretium elit sem quis orci. Vivamus condimentum dictum tempor. Nam at est ante. Sed lobortis et lorem in sagittis. In suscipit in est et vehicula.</p>
            </div>
          </div>

        </div>
      </div> */}
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <HomePageSlider />
        </div>
      </div>

     <TrendingTransport />
     <TrendingHotel />

      
      {/* <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '30vh' }}>
          <Testimonial />
        </div>
      </div> */}
    </>
  )
}
