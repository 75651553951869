import React, { useEffect, useState } from 'react'
import SinglePageSlider from '../utils/SinglePageSlider'
import SingleSidebar from '../utils/SingleSidebar'
import './css/ListingPage.css'
import './css/SinglePage.css'
import axios from 'axios'
import Loader from '../utils/Loader'
import { useParams } from 'react-router-dom'
import QuoteModal from '../utils/QuoteModal'

function SinglePage() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { type, id } = useParams();
  const [modalState, setModalState] = useState(false);

  const initialState = {
    brand: '',
    model: '',
    launchYear: '',
    vehicleColour: '',
    vehicleNo: '',
    organization: '',
    owner: '',
    driver: '',
    features: [{}],
    uploads: '',
    category:'',
    subCategory: ''
  }

  const [transportState, setTransportState] = useState(initialState);
  const [loading, setLoading] = useState(true);
  
  const [idState, setIdState ] = useState('');
  const [featureImage, setFeatureImage] = useState();

  const fetchData = () => {

  
    axios.get(`${baseUrl}/api/v1/transport/list?trId=${id}`).then((res) => {
      console.log("Response for fetchdata", res.data.data);
      if(res.data.status === 'OK'){
        setTransportState(res.data.data);
        setFeatureImage(res.data.data.uploads.featuredImage);
      console.log("RESPONSE IS   ==", transportState.uploads);
      console.log("FEATURE IMAGE IS   ==" + featureImage);
      console.log(baseUrl, "BASE URL")
      setLoading(false);
      }
      
    }).catch(err => {
      console.log("ERROR    ", err);
      console.log("BASE URL    ", baseUrl);
    });

  }


function getModalState(data){

  setModalState(data);
}
  useEffect(() =>{
    console.log('USE PARAMS ==='+id);
    setIdState(id);
    
      fetchData();
   
    // fetchData();
  }, [])
  return (
    <>
    <div className='container'>
    {loading ? <Loader /> : ''}
      <div className='row'>
      <div className='col-lg-8 col-md-8 col-sm-12'>
        {/* <pre>
          { transportState.uploads.featuredImage }
        </pre> */}
        <SinglePageSlider slide={transportState.uploads.featuredImage} />
        <div className='col-lg-12 col-md-12 col-sm-12 bgColor mb-3'>
        <div className='single-internal' style={{padding:'3%'}}>
        <h2>{transportState.organization?.orgName}</h2>

<section className='features'>
  <h3 className='title mt-3'>Our Features</h3>
  <ul>
    <li>Best Transport Service provider in Gurugram </li>
    <li>New Model with exciting Features</li>
    <li>Unalchoholic Driver with well behaved</li>
    <li>10+ Experinced Driver</li>
    <li>Cleaning is high priority </li>
    <li>Pollution Certificate is valid </li>
    <li>Well maintend Vehicle  </li>
    <li>Best Transport Service provider in Gurugram </li>
  </ul>
  </section>   
      
        </div>
         
        </div>
        </div>
        <div className='col-lg-4 col-md-4 col-sm-12 vh-100'>
         <SingleSidebar data={transportState} showModal={getModalState} />
        </div>
      </div>
    </div>
   
    {modalState && <QuoteModal closeModal={setModalState} serviceId={transportState.id} serviceType="transport" />}
      
    </>
        

    
  )
}

export default SinglePage
