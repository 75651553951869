import React, { useState } from 'react'
import './css/Signup.css';
import { Form } from 'react-bootstrap';
import BootstrapDatePickerComponent from '../utils/BootstrapDatePicker';
import loginImg from '../images/weding-login.webp'
import ThankYouModal from '../utils/ThankYouModal';
import { useForm } from 'react-hook-form';
import axios from 'axios'
import ErrorModal from '../utils/ErrorModal';

export default function Signup() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [failedResponse, setFailedResponse] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = (data) => { 
    
    setFormSubmitted(true);
    const postData = {
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      dob: data.dob,
      mobileNo: data.mobileNo,
      email: data.email,
      password: data.password,
      roles: [data.roles]
    }
    console.log(postData);
    axios.post(baseUrl + "/api/v1/auth/register", postData).then((res) => {
      console.log("Response for fetchdata", res.data.msg);
      console.log("Response for fetchdata", res.data.status);
      if (res.data.status == 'OK') {
        setShowModal(true);
        setFormSubmitted(false);
      } 
      else {
         // setFailedResponse(res.data.msg)
         setErrorContent(res.data.msg);
         setFormSubmitted(false);
      }

      console.log(baseUrl, "BASE URL")
  }).catch(err => {
    setErrorContent(err);
      console.log("ERROR    ", err);
      console.log("BASE URL    ", baseUrl);
      setFormSubmitted(false);
  });
    
  }

  

  const myStyle = {
    backgroundImage:
      "url('../images/bg.jpg')",
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <section className="vh-100">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center h-100">

          <div className="col-md-10 col-lg-10 mx-auto">
           
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className='col-md-5 col-lg-5'>
                  <img src={loginImg}
                    alt="login form" className="img-fluid" style={{ borderRadius: "1rem 0 0 1rem" }} />
               <img src={loginImg}
                    alt="login form" className="img-fluid" style={{ borderRadius: "1rem 0 0 1rem" }} />
                </div>
                <div className="col-md-7 col-lg-7 align-items-center">
                
                  <div className="card-body p-4 p-lg-5 text-black">
                    <p className="text-center mx-auto">
                    <i style={{ color: "#a09a9a", fontSize: "3rem" }} className="bx bx-notepad"></i>
                    <h4 className='title py-2' style={{ color: "#a09a9a", fontSize: "1rem", textAlign:"center" }}>Register Yourself to grow your Business</h4>
                    </p>
                    {errorContent.length > 0 ? 
                  <div className="alert alert-danger" role="alert">
                      {errorContent}
                   </div>:""
                   }
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>

                      <div className="col-md-12 col-lg-12 d-flex">
                    
                        <div className="col-md-6 col-lg-6 align-items-center">
                       
                          <div className="form-outline mb-3">
                            <label className="form-label-dk" htmlFor="form2Example17">First Name</label>
                            <input type="text" name="firstName" {...register("firstName", { required: true })} className="form-control form-control-md" />
                            {errors.firstName && <span className="form-error-dk">This field is required</span>}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 align-items-center">
                          <div className="form-outline mb-3 mx-2">
                            <label className="form-label-dk" htmlFor="form2Example17">Last Name</label>
                            <input type="text" name="lastName" {...register("lastName", { required: true })} className="form-control form-control-md" />
                            {errors.lastName && <span className="form-error-dk">This field is required</span>}
                          </div>
                        </div>

                      </div>


                      <div className="form-outline mb-2">
                        <label className="form-label-dk" htmlFor="form2Example17">Gender</label>
                        <select className="form-select-dk form-select mt-0" name="gender" {...register("gender", { required: true })}>
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Ambiguous">Ambiguous</option>
                        </select>
                        {errors.gender && <span className="form-error-dk">This field is required</span>}
                      </div>
                      <div className="form-outline mb-2">
                        <label className="form-label-dk" htmlFor="form2Example17">Date Of Birth</label>
                        {/* <BootstrapDatePickerComponent /> */}
                        <input type="date" name="dob" {...register("dob", { required: true })} className="form-control form-control-md" />
                        {errors.dob && <span className="form-error-dk">This field is required</span>}
                      </div>
                      <div className="form-outline mb-2">
                        <label className="form-label-dk" htmlFor="form2Example17">Register As</label>
                        <select name="roles" {...register("roles", {required:true})} className="form-select-dk form-select mt-0">
                          <option value="">Select Role</option>
                          <option value="1">User</option>
                          <option value="2">Customer</option>
                          <option value="3">Service Provider</option>
                        </select>
                        {errors.roles && <span className="form-error-dk">This field is required</span>}
                      </div>
                      <div className="form-outline mb-2">
                        <label className="form-label-dk" htmlFor="form2Example17">Mobile No</label>
                        <input type="email" name="mobileNo" {...register("mobileNo", {required:true})}  className="form-control form-control-md" />
                        {errors.mobileNo && <span className="form-error-dk">This field is required</span>}
                      </div>
                      <div className="form-outline mb-2">
                        <label className="form-label-dk" htmlFor="form2Example17">Email address</label>
                        <input type="email" name="email" {...register("email", {required:true})}  className="form-control form-control-md" />
                        {errors.email && <span className="form-error-dk">This field is required</span>}
                      </div>
                      
                      <div className="form-outline mb-3">
                        <label className="form-label-dk" htmlFor="form2Example27">Password</label>
                        <input type="password" name="password" {...register("password", {required:true})} className="form-control form-control-md" />
                        {errors.password && <span className="form-error-dk">This field is required</span>}
                      </div>

                      <div className="col-md-12 col-lg-12 d-flex ">
                        <div className="pt-1 mb-12">
                          <button className="btn btn-dark btn-lg btn-block" type="submit" disabled={formSubmitted}>Signup</button>
                        </div>

                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <ThankYouModal closeModal={setShowModal} />}
      {showModal && <ErrorModal closeModal={setShowModal} content={setErrorContent} />}
    </section>
  )
}
