import React from 'react'
import { Link } from 'react-router-dom'
import { BiAlarmAdd } from "react-icons/bi";
import './SpDashboard.css'

export default function SpDashboardSidebar() {
  return (
    <>
      
  <div className="side-navbar d-flex justify-content-between flex-wrap flex-column" id="sidebar">
    <ul className="nav flex-column text-white w-100">
      <a href="#" className="nav-link h3 text-white my-4">
        
      </a>
      <li href="#" className="nav-link">
      <Link className="nav-link-inner" to="/dashboard">
        <i className="bx bxs-dashboard"></i>
        <span className="mx-2"> Home</span>
        </Link>
      </li>
     

     

      <li href="#" className="nav-link" >
        <Link to='/dashboard/sp/myservices' className="nav-link-inner" >
        <i className="bx bxs-dashboard"></i>
        <span className="mx-2">My Services</span>

          </Link>
          
      </li>
      <li href="#" className="nav-link">
        <i className="bx bx-conversation"></i>
        <span className="mx-2">Contact</span>
      </li>
    </ul>

    <span href="#" className="nav-link h4 w-100 mb-5">
      <a href=""><i className="bx bxl-instagram-alt text-white"></i></a>
      <a href=""><i className="bx bxl-twitter px-2 text-white"></i></a>
      <a href=""><i className="bx bxl-facebook text-white"></i></a>
    </span>
  </div>
    </>
  )
}
