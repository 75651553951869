import React from 'react'
import { Link } from 'react-router-dom'
import StarRating from '../../utils/StarRating'

function TrendingHotel() {
  return (
    <div>
       <div className='container'>
      <div className='row' style={{backgroundColor:'#fff', marginTop:'2%', minHeight:'20vh', borderRadius:'3px', padding:'2% 0%'}}>
        <div>
        <h2 className='home-title' style={{fontSize:'1.5rem', padding:'1% 1%', fontWeight:'bold', width:'90%'}}>Trending Hotel Services</h2>
         <Link to='category/hotels' className="btn-link float-end" style={{marginTop:'-50px'}}>View All</Link>
        </div>
         
          <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className='list-inner' style={{boxShadow:'0px 0px 1px #333', padding:'1%'}}>
              <div className='images'>
                <img src='https://r1imghtlak.mmtcdn.com/d74eb5c29df411ebba010242ac110002.jpg?&output-quality=75&downsize=910:612&crop=910:612;0,149&output-format=jpg&downsize=821:550&crop=821:550' className='img product-img' alt='Service featured image' style={{maxWidth:'100%', height:'200px'}} />
              </div>
              <div className='bottom-content'>
                <h2>Deepak Transport Service</h2>
                <div className="d-grid gap-2  mx-auto d-flex">
                       <p><i class='bx bx-current-location' style={{color:'#ffc107', marginTop:'5px'}}></i><span style={{fontSize:'13px', paddingLeft:'5px'}}>Gurugram</span></p>
                        <p style={{fontSize:'10px', marginLeft:'auto', paddingBotto:'0px'}}><StarRating value="4.6" /></p>
                        
                      </div>
                <div className="d-grid gap-2 col-12 mx-auto mt-1 mb-1">
                        <button className="btn btn-listing" type="button"><Link to={`/single/transport/`} className="btn-link">View Details</Link></button>
                      </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className='list-inner' style={{boxShadow:'0px 0px 1px #333', padding:'1%'}}>
              <div className='images'>
                <img src='https://stimg.cardekho.com/images/carexteriorimages/630x420/Mercedes-Benz/EQS/9275/1661409775993/front-left-side-47.jpg' className='img product-img' alt='Service featured image' style={{maxWidth:'100%', height:'200px'}} />
              </div>
              <div className='bottom-content'>
                <h2>Deepak Transport Service</h2>
                <div className="d-grid gap-2  mx-auto d-flex">
                       <p><i class='bx bx-current-location' style={{color:'#ffc107', marginTop:'5px'}}></i><span style={{fontSize:'13px', paddingLeft:'5px'}}>Gurugram</span></p>
                        <p style={{fontSize:'10px', marginLeft:'auto', paddingBotto:'0px'}}><StarRating value="4.6" /></p>
                        
                      </div>
                <div className="d-grid gap-2 col-12 mx-auto mt-1 mb-1">
                        <button className="btn btn-listing" type="button"><Link to={`/single/transport/`} className="btn-link">View Details</Link></button>
                      </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className='list-inner' style={{boxShadow:'0px 0px 1px #333', padding:'1%'}}>
              <div className='images'>
                <img src='https://stimg.cardekho.com/images/carexteriorimages/630x420/BMW/M8-Coupe-Competition/9373/1664537867224/front-left-side-47.jpg' className='img product-img' alt='Service featured image' style={{maxWidth:'100%', height:'200px'}} />
              </div>
              <div className='bottom-content'>
                <h2>Deepak Transport Service</h2>
                <div className="d-grid gap-2  mx-auto d-flex">
                       <p><i class='bx bx-current-location' style={{color:'#ffc107', marginTop:'5px'}}></i><span style={{fontSize:'13px', paddingLeft:'5px'}}>Gurugram</span></p>
                        <p style={{fontSize:'10px', marginLeft:'auto', paddingBotto:'0px'}}><StarRating value="4.6" /></p>
                        
                      </div>
                <div className="d-grid gap-2 col-12 mx-auto mt-1 mb-1">
                        <button className="btn btn-listing" type="button"><Link to={`/single/transport/`} className="btn-link">View Details</Link></button>
                      </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className='list-inner' style={{boxShadow:'0px 0px 1px #333', padding:'1%'}}>
              <div className='images'>
                <img src='https://stimg.cardekho.com/images/carexteriorimages/630x420/Tata/Nexon/7384/1614326304397/front-left-side-47.jpg' className='img product-img' alt='Service featured image' style={{maxWidth:'100%', height:'200px'}} />
              </div>
              <div className='bottom-content'>
                <h2>Deepak Transport Service</h2>
                <div className="d-grid gap-2  mx-auto d-flex">
                       <p><i class='bx bx-current-location' style={{color:'#ffc107', marginTop:'5px'}}></i><span style={{fontSize:'13px', paddingLeft:'5px'}}>Gurugram</span></p>
                        <p style={{fontSize:'10px', marginLeft:'auto', paddingBotto:'0px'}}><StarRating value="4.6" /></p>
                        
                      </div>
                <div className="d-grid gap-2 col-12 mx-auto mt-1 mb-1">
                        <button className="btn btn-listing" type="button"><Link to={`/single/transport/`} className="btn-link">View Details</Link></button>
                      </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default TrendingHotel
