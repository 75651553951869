import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TransportCommon from '../../ad/transport/TransportCommon';
import { useDispatch } from 'react-redux';
import { setCategoryAction, setFormDataAction } from '../../../../redux/action/multiStepFormAction';
import { AdsCategory } from '../../../../constants';


function EditTransportCommon() {

  const baseUrl = process.env.REACT_APP_BASE_URL;


  const { type, id } = useParams();
  const dispatch = useDispatch();

  const [uploadState, setUploadState] = useState({
    id: '',
    featuredImage: '',
    gallery: [''],
    vehicleDocs: '',
    pollutionCertificate: '',
    driverLicence: ''


  });

  const [transportState, setTransportState] = useState({
    id: '',
    brand: '',
    model: '',
    launchYear: '',
    vehicleColour: '',
    vehicleNo: '',
    organization: '',
    owner: '',
    driver: '',
    features: [{}],
    uploads: '',
    featuredImage: '',
    gallery: [''],
    category: '',
    subCategory: ''
  })

  function setFormFilledData(data) {
    dispatch(setFormDataAction({
      type: AdsCategory.transport,
      data: data
    }))
  }

  const fetchData = () => {
    axios.get(`${baseUrl}/api/v1/transport/list?trId=${id}`).then((res) => {
      console.log("Response for fetchdata", res.data.data);
      if (res.data.status === 'OK') {
        setTransportState(res.data.data);
        const data = res.data.data;
        dispatch(setFormDataAction({
          type: AdsCategory.transport,
          data: {
            "vehicle": {
              brand: data.brand,
              model: data.model,
              launchYear: data.launchYear,
              vehicleColour: data.vehicleColour,
              vehicleNo: data.vehicleNo,
            }
          }
        }))
        dispatch(setCategoryAction({
          category: +res.data.data.categoryId,
          subCategory: +res.data.data.subCategoryId,
        }))

        // setOrgState(res.data.data?.organization);
        // setOwnerState(res.data.data?.owner)
        // setDriverState(res.data.data?.driver)
        // setFeatureImage(res.data.data.uploads.featuredImage);
        // setUploadState(res.data.data.uploads?.uploads)
        // setFeatureState(res.data.data.uploads.features)
        // console.log("RESPONSE IS   ==", transportState.uploads);
        // console.log("FEATURE IMAGE IS   ==" + featureImage);
        // console.log(baseUrl, "BASE URL")
        // setLoading(false);
      }

      setFormFilledData(res.data.data)

    }).catch(err => {
      console.log("ERROR    ", err);
      console.log("BASE URL    ", baseUrl);
    });
  }




  function getSummaryData(data) {
    console.log("Summary Data ==" + data);
    // setTransportState(
    //   {
    //     id: vehicleState?.id,
    //     brand: vehicleState?.brand,
    //     model: vehicleState?.model,
    //     launchYear: vehicleState?.launchYear,
    //     vehicleColour: vehicleState?.vehicleColour,
    //     vehicleNo: vehicleState?.vehicleNo,
    //     organization: orgState,
    //     owner: ownerState,
    //     driver: driverState,
    //     features: featureState,
    //     uploads: uploadState,
    //     category: vehicleState?.category,
    //     subCategory: vehicleState?.subCategory,

    //   }
    // );
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <TransportCommon />
  )
}

export default EditTransportCommon
