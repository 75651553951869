import React, { useState } from 'react'
import './util.css'
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from 'react-hook-form';
import axios from 'axios'


export default function QuoteModal({closeModal, serviceId, serviceType}) {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  function onChange(value){
    console.log("Captcha value:", value);
  }
  const [response, setResponse] = useState("");
  const [failedResponse, setFailedResponse] = useState("");
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = (requestedData) => { 
    requestedData = {
      name: requestedData.name,
      email: requestedData.email,
      mobileNo: requestedData.mobileNo,
      message: requestedData.message,
      serviceId: serviceId,
      serviceType: serviceType
    }
    console.log(requestedData);
      setResponse("");
      setFailedResponse("");
    axios.post(baseUrl+"/api/v1/common/new-quote", requestedData).then((res) =>{
        console.log("Response for fetchdata",res.data.msg);
        console.log("Response for fetchdata",res.data.status);
        if(res.data.status == 'OK'){
            setResponse(res.data.msg);
        }else{
            setFailedResponse(res.data.msg)
        }
        console.log(baseUrl,"BASE URL")
       }).catch(err =>{
         console.log("ERROR    ",err);
         console.log("BASE URL    ",baseUrl);
       });
  }
  return (
    <div>
<div id="myModal" className="modal1">
<div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">Get The Quotes</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{closeModal(false)}}></button>
      </div>
      <div className="modal-body">

<form noValidate onSubmit={handleSubmit(onSubmit)}>
<div className="form-outline mb-3">
{response.length > 0 ? 
                  <div class="alert alert-success" role="alert">
                      {response}
                   </div>:""
                   }
                    {failedResponse.length > 0 ? 
                  <div class="alert alert-danger" role="alert">
                      {failedResponse}
                   </div>:""
                   }
  <label className="form-label-dk" htmlFor="form2Example17">Name</label>
    <input type="text" name="name" {...register("name", { required: true })} className="form-control form-control-md" />
    {errors.name && <span className="form-error-dk">This field is required</span>}
    </div>
    <div className="form-outline mb-2">
  <label className="form-label-dk" htmlFor="form2Example17">Email address</label>
    <input type="email" name="email" {...register("email", { required: true })} className="form-control form-control-md" />
    {errors.email && <span className="form-error-dk">This field is required</span>}
  </div>

  <div className="form-outline mb-2">
  <label className="form-label-dk" htmlFor="form2Example17">Mobile Number</label>
    <input type="number" name="mobileNo" {...register("mobileNo", { required: true })} className="form-control form-control-md" />
    {errors.email && <span className="form-error-dk">This field is required</span>}
  </div>

  <div className="form-outline mb-2">
  <label className="form-label-dk" htmlFor="form2Example17">Message</label>
  <textarea className="form-control form-control-md" name="message" {...register("message", { required: true })}></textarea>
    {/* <input type="email" id="form2Example17" className="form-control form-control-md" /> */}
    {errors.message && <span className="form-error-dk">This field is required</span>}
  </div>
  
 
  {/* <ReCAPTCHA
    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
    onChange={onChange} /> */}
   
  <div className="col-md-12 col-lg-12 d-flex w-100 mt-3">
  <div className="pt-1 mb-2 mx-auto">
    <button className="btn btn-warning btn-lg" type="submit">Submit</button>
  </div>
  </div>
  
</form>
      </div>
      
    </div>

</div>
</div>

  )
}
