import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ThankYouModal from '../../../../../utils/ThankYouModal';
import './common.css'
import DriverDetail from './DriverDetail';
import LeftSideProgress from './LeftSideProgress'
import OwnerDetail from './OwnerDetail';
import Summary from './Summary';
import Upload from './Upload';
import VehicleDetail from './VehicleDetail';
import axios from 'axios';
import OrganizationDetails from './OrganizationDetails';

function CommonVehicleFile() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
    const [step, setStep] = useState(1);
    const [openModal, setModal] = useState(false);
    const [response, setResponse] = useState("");
    const [failedResponse, setFailedResponse] = useState("");

    const formData = new FormData();

    const defaultFormValue = {
      "brand": "",
      "model": "",
      "colour": "",
      "launchYear": "",
      "vehicleNo": "",
      "ownerFirstName":"",
      "ownerLastName":"",
      "ownerContactNumber":"",
      "ownerEmail":"",
      "ownerGender":"",
      "ownerDob":"",
      "driverFirstName":"",
      "driverLastName":"",
      "driverContactNumber":"",
      "driverEmail":"",
      "driverGender":"",
      "driverDob":"",
      "driverKnownLanguage":"",
      "driverLicence":"",
      "vehicleCertificate":""
  }

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    
const increaseStep = () =>{
  
    //console.log("Form value",...register);
  // if(!errors){
    setStep(step+1);
   console.log("step is "+step);
  // }else{
  //   console.log("Errors", errors);
  // }
   
    //  <LeftSideProgress currentStep={step} />

}
const jwtToken = localStorage.getItem('loginToken');
const config = {
  headers: { Authorization: `Bearer ${jwtToken}` }
};
  const onSubmit = (reqData) => {
    
    const dataObj = {
      "brand": reqData.brand,
      "model": reqData.model,
      "vehicleColor": reqData.vehicleColor,
      "launchYear": reqData.launchYear,
      "vehicleNo": reqData.vehicleNo,
      "ownerFirstName": reqData.ownerFirstName,
      "ownerLastName": reqData.ownerLastName,
      "ownerMobileNo": reqData.ownerMobileNo,
      "ownerEmailId": reqData.ownerEmail,
      "ownerGender": reqData.ownerGender,
      "ownerDob": reqData.ownerDob,
      "driverFirstName":reqData.driverFirstName,
      "driverLastName": reqData.driverLastName,
      "driverMobileNo": reqData.driverMobileNo,
      "driverEmailId": reqData.driverEmailId,
      "driverGender": reqData.driverGender,
      "driverDob": reqData.driverDob,
      "driverLanguage": reqData.driverLanguage
    }
//debugger
    formData.append('data', JSON.stringify(dataObj));
    formData.append("vehicleDocs", reqData.vehicleDocs[0]);
    formData.append("pollutionCertificate", reqData.pollutionCertificate[0]);
    formData.append("featuredImage", reqData.featuredImage[0]);
    formData.append("driverLicence", reqData.driverLicence[0]);
  console.log("Requested data", dataObj);
  axios.post(baseUrl + "/api/v1/transport/add", formData, config).then((res) => {
    console.log("Response for fetchdata", res.data.msg);
    console.log("Response for fetchdata", res.data.status);
    if (res.data.status === 'OK') {
        setResponse(res.data.msg);
        setModal(true);
    } else {
        setFailedResponse(res.data.msg)
    }

    console.log(baseUrl, "BASE URL")
}).catch(err => {
    console.log("ERROR    ", err);
    console.log("BASE URL    ", baseUrl);
});
  }
    useEffect(() =>{
        setStep(1);
       
    },[])

  return (
    <>
      
    <div className="container mycontainer" style={{display:'flex'}}>
    <div className="col-lg-3 col-md-3 left-side">
                 <LeftSideProgress currentStep={step} />  
            </div>
            <div className="col-lg-9 col-md-9 right-side">
              <p>{failedResponse}</p>
             
            <div className="form">
       <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)} >
                {(() => {
        switch (step) {
          case 1:
            return <OrganizationDetails register={register} errors={errors} />
          case 2:
            return <VehicleDetail register={register} errors={errors} />
          case 3:
            return <OwnerDetail register={register} errors={errors} />
          case 4:
            return <DriverDetail register={register} errors={errors} />
          case 5:
            return <Upload register={register} errors={errors} />
          case 6:
            return <Summary />  
          
        }
      })()}
                     <div className="buttons button_space">
                         {step > 1 ?
                        <button className="back_button" onClick={()=> setStep((curStep)=> curStep-1)}>Back</button>:""
                         }
                        {step < 5 ?
                        // <button  className="next_button" onClick={()=> setStep((curStep)=> curStep+1)}>Next Step</button>:
                        <button className="next_button" type="button" onClick={increaseStep}>Next Step</button>:
                        <button className="next_button" type="submit">Submit</button>
                        }
                    </div>
                    <pre>
                      {JSON.stringify(watch(), null, 2)}
                    </pre>
                    </form>
            </div>
            
        </div>
        {openModal && <ThankYouModal closeModal={setModal} /> }
    </div>

    </>
  )
}

export default CommonVehicleFile
