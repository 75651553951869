import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import EditBrand from './EditBrand';

function BrandList() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('loginToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` }
};
  const fetchBrandData = () =>{
    axios.get(baseUrl+"/api/v1/vehicle/brand/list", config).then((res) =>{
     console.log("Response",res.data.data);
     setBrands(res.data.data);
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
    
  }

    function initiateJquery(){
        
        
        $(document).ready(function() {
            var table = $('#example').DataTable( {
                lengthChange: false,
                buttons: [ 'copy', 'excel', 'pdf', 'colvis' ]
            } );
         
            table.buttons().container()
                .appendTo( '#example_wrapper .col-md-6:eq(0)' );
        } );
    }

    const deleteBrand = (item)=>{
     console.log(item);
    }

    const updateBrand = (item) =>{
        console.log(item,"jjj");
        //  <EditBrand data={item} />

        const encodedBrandId = btoa(item.brandId);
         let updatePath = `/dashboard/edit-brand/${encodedBrandId}`;
         navigate(updatePath);
        console.log(item);
       }

       


    useEffect(()=>{

     fetchBrandData();
     // initiateJquery(); 

    }, [])
  return (
    <div className="col-lg-12 col-md-12 mx-auto" style={{marginTop:"7%", backgroundColor:"#fff"}}>
      <table className="table table-striped">
        <thead>
            <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>Label</th>
                 <th>Action</th>
                
            </tr>
        </thead>
        <tbody>
            {brands.length > 0 ? brands.map((item, index)=>{
                return(
                    <tr key={index}>
                <td>{index+1}</td>
                <td>{item.brandName}</td>
                <td>{item.brandName}</td>
                <td>
                    <ul className='d-flex action-list'>
                        <li><button className='btn btn-primary' onClick={() => updateBrand(item)}><i style={{fontSize:"1rem"}} className="bx bx-edit"></i></button></li>
                        <li><button className='btn btn-danger' onClick={() => deleteBrand(item.brandId)}><i style={{ fontSize:"1rem"}} className="bx bx-trash"></i></button></li>
                    </ul>
                </td>
                
            </tr>
                )
            }):<tr>
           <td colSpan={5}>No data Available</td>
            </tr>
}  
            
           
           
        </tbody>
       
    </table>
    </div>
  )
}

export default BrandList
