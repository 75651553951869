import { MAKEUP_LIST, SEARCH_SERVICE, TRANSPORT_LIST } from "../constant"


export const makeupList = () => {
  
    return {
        type: MAKEUP_LIST,
    }
}

export const serviceSearch = (query) => {
  
    return {
        type: SEARCH_SERVICE,
        query
    }
}