import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { fileToBlob } from '../../../../utils/helpers';

function HotelUploadsForm({
  type,
  setStep,
  setFormFilledData,
}) {

  const [fetauredImg, setFeaturedImg] = useState();
  const [galleryImg, setGalleryImg] = useState();
  const uploadState = useSelector(state => state.multiStepForm.hotel.uploads);
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: uploadState
  });

  useEffect(() => {
    if (uploadState.featuredImage) {
      setFeaturedImg(uploadState.featuredImage);
    }
    if (uploadState.gallery) {
      setGalleryImg(uploadState.gallery);
    }
  }, [uploadState]);

  const backStep = () => {
    setStep(3);
  }
  const onSubmit = (data) => {
    const imgData = {
      featuredImage: fetauredImg,
      gallery: galleryImg
    }
    setFormFilledData(imgData, type)
    setStep(5);
  };

  const featureImageChanged = (e) => {
    // console.log("featured image ==" + e.target.files[0]);
    setFeaturedImg(e.target.files[0]);
  }

  const galleryChanged = (e) => {
    // console.log("gallerry image ==" + e);
    setGalleryImg(e.target.files[0]);
  }

  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>


          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <h3 style={{ color: '#ff9800', fontSize: '13px' }}>Feature Image</h3>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%' }}>
                <div className='row'>
                  <div className="col-lg-4 col-sm-12">
                    {/* <label className="form-label-dk"> Feature Image</label> */}
                    <input type="file" accept="image/*" className="form-control" name="featuredImage" {...register("featuredImage", { required: true })} onChange={featureImageChanged} />
                    {errors.featuredImage && <span className="form-error-dk">This field is required</span>}
                  </div>
                  <div className="col-lg-8 col-sm-12">
                    {fetauredImg && <img src={fileToBlob(fetauredImg)} style={{ width: '150px', height: '200px' }} alt="feature" />}
                  </div>
                </div>
              </section>
            </div>
            <div className="row">
              <h3 style={{ color: '#ff9800', fontSize: '13px', paddingTop: '2%' }}>Gallery Images</h3>
              <section className='orgSection' style={{ border: '1px solid #ccc', padding: '1%', marginTop: '0%' }}>
                <div className='row'>
                  <div className="col-lg-4 col-sm-12">
                    {/* <label className="form-label-dk"> Gallery Image</label> */}
                    <input type="file" accept="image/*" className="form-control" name="gallery" {...register("gallery", { required: true })} onChange={galleryChanged} multiple />
                    {errors.gallery && <span className="form-error-dk">This field is required</span>}
                  </div>
                  <div className="col-lg-8 col-sm-12">
                    {galleryImg && <img src={fileToBlob(galleryImg)} style={{ width: '150px', height: '200px' }} alt="gallery" />}
                  </div>
                </div>
              </section>
            </div>

            <div className='row mt-5'>
              <div className='col'>
                <>
                  <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                  <button className="btn btn-warning" type="submit">Next Step</button>
                </>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default HotelUploadsForm
