import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import './css/Header.css';
import logoImg from '../images/bws-logo.png';
import cartImg from '../images/shopping-cart.png';
import SearchBar from '../utils/SearchBar';
import UserMenu from './user-components/UserMenu';
import MainMenu from '../utils/MainMenu';

export default function Header(props) {

    const loginToken = localStorage.getItem('loginToken');
    const [showProfile, setShowProfile] = useState(false);
    const [token, setToken] = useState(loginToken);

    const clikedOnUser = () => {

        if (showProfile === false)
            setShowProfile(true);
        else
            setShowProfile(false);
    }

    const logout = () => {
        console.log("logged out");
        if (loginToken != null) {
            localStorage.removeItem('loginToken');
            setToken(null);
        }
    }
    const useEffect = (() => {
        console.log("Use effect called");
    }, [])

    return (
        <>
            <header>
                <div className="container-fluid" style={{ marginBottom: '5%' }}>
                    <div className="row fixed-top" style={{ backgroundColor: "#1e404e" }}>
                        <div className="col-lg-3 col-md-3">
                            <nav className="navbar navbar-expand-xl navbar-light">
                                <div className="container-fluid">
                                    <Link to="/" className="navbar-brand">
                                        <span className='logo-text'>Shadi<i>material</i></span>
                                    </Link >
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                </div>
                            </nav>
                        </div>

                        <div className="col-lg-6 col-md-6 mt-9">
                            <SearchBar />
                        </div>

                        <div className="col-lg-3 col-md-2 mt-3 cart-login">
                            {token ?
                                <div className="float-end cart mt-0">

                                    <ul className="nav flex-row text-white">
                                        <li href="#" className="nav-link" >
                                        <button type="button" class="btn btn-warning position-relative" style={{marginTop:'-7%'}}>
                                                 <Link to='sp/category/add'>Post Ad</Link>
                                            </button>
                                        </li>
                                        <li href="#" className="nav-link">
                                            <button type="button" class="btn btn-default position-relative" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                                <i className="bx bxs-bell"></i>
                                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                    99+
                                                    <span class="visually-hidden">unread messages</span>
                                                </span>
                                            </button>
                                            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                                <div class="offcanvas-header">
                                                    <h5 class="offcanvas-title" id="offcanvasRightLabel">Notifications</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                </div>
                                                <div class="offcanvas-body">
                                                <section className='features'>
  
  <ul>
    <li>Best Transport Service provider in Gurugram </li>
    <li>New Model with exciting Features</li>
    <li>Unalchoholic Driver with well behaved</li>
    <li>10+ Experinced Driver</li>
    <li>Cleaning is high priority </li>
    <li>Pollution Certificate is valid </li>
    <li>Well maintend Vehicle  </li>
    <li>Best Transport Service provider in Gurugram </li>
  </ul>
  </section> 
                                                </div>
                                            </div>
                                        </li>

                                        <li className="dropdown">
                                            <button type="button" class="btn btn-default position-relative" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true" style={{ color: '#fff' }}>
                                                <i className="bx bxs-user btn btn-default" style={{ color: '#fff' }} ></i>
                                            </button>

                                            <ul className="dropdown-menu user-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="#">Hi, Deepak</a></li>
                                                <li><a className="dropdown-item" href="#">Profile</a></li>
                                                <li><Link className="dropdown-item" to="/dashboard/sp">Dashboard</Link></li>
                                                <li><a className="dropdown-item" href="javascript:void(0)" onClick={logout}>Logout</a></li>
                                            </ul>

                                        </li>
                                    </ul>


                                </div> :
                                <div className="float-end">
                                    <ul className="nav flex-row text-white">
                                        <li href="#" className="nav-link" >
                                        <button type="button" class="btn btn-warning position-relative" style={{marginTop:'-7%'}}>
        
                                                 <span>Post Ad</span>
                                            </button>
                                        </li>
                                        <li className="nav-link">
                                        <button type="button" class="btn btn-light position-relative" style={{marginTop:'-7%'}}><Link to="/login" className='loginText'>Login</Link></button>
                                   
                                        </li>
                                        </ul>
                                    
                                </div>
                            }

                        </div>
                        <div className='col-lg-12 col-md-12' style={{ backgroundColor: '#ebeeef !important' }}>

                            <MainMenu />

                        </div>

                    </div>
                </div>
            </header>

        </>



    )
}
