import React from 'react'

function ThankYouModal({ closeModal, onClose }) {
  function handleModalClose() {
    if (onClose) {
      onClose()
    }
    closeModal(false)
  }
  return (
    <>
      <div id="myModal" className="modal1">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="staticBackdropLabel"></h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleModalClose}></button>
          </div>
          <div className='modal-body'>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>

            <div className="text congrats">
              <h2 style={{ fontSize: '2rem', padding: '5%' }}>Congratulations!</h2>
              <p style={{ fontSize: '.9rem', padding: '4%', color: '#333' }}><span className="shown_name"></span> Your information have been submitted successfully for the future reference we will contact you soon.</p>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default ThankYouModal
