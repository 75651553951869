import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';


function ModelList() {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('loginToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` }
};
  const fetchModelData = () =>{
    axios.get(baseUrl+"/api/v1/vehicle/model/list", config).then((res) =>{
     console.log("Response",res.data.data);
     setModels(res.data.data);
    }).catch(err =>{
      console.log("ERROR    ",err);
    });
    
  }

   

    const deleteModel = (item)=>{
     console.log(item);
    }

    const updateModel = (item) =>{
        console.log(item,"jjj");
        //  <EditBrand data={item} />

        const encodedModelId = btoa(item.modelId);
         let updatePath = `/dashboard/edit-model/${encodedModelId}`;
         navigate(updatePath);
        console.log(item);
       }

       
    useEffect(()=>{

     fetchModelData();
     // initiateJquery(); 

    }, [])
  return (
    <div className="col-lg-12 col-md-12 mx-auto" style={{marginTop:"7%", backgroundColor:"#fff"}}>
      <table className="table table-striped">
        <thead>
            <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>Label</th>
                 <th>Action</th>
                
            </tr>
        </thead>
        <tbody>
            {models.length > 0 ? models.map((item, index)=>{
                return(
                    <tr key={index}>
                <td>{index+1}</td>
                <td>{item.modelName}</td>
                <td>{item.modelLabel}</td>
                <td>
                    <ul className='d-flex action-list'>
                        <li><button className='btn btn-primary' onClick={() => updateModel(item)}><i style={{fontSize:"1rem"}} className="bx bx-edit"></i></button></li>
                        <li><button className='btn btn-danger' onClick={() => deleteModel(item.modelId)}><i style={{ fontSize:"1rem"}} className="bx bx-trash"></i></button></li>
                    </ul>
                </td>
                
            </tr>
                )
            }):<tr>
           <td colSpan={5}>No data Available</td>
            </tr>
}  
            
           
           
        </tbody>
       
    </table>
    </div>
  )
}

export default ModelList
