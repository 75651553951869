import { SET_TRANSPORT_LIST } from "../constant"



export const transportData = (data = [], action) => {
    switch (action.type) {
            case SET_TRANSPORT_LIST:
                console.warn("PRODUCT_LIST condition ", action)
                return [...action.data]
        default:
            return data
    }
}